import React, { useEffect, useRef, useState } from 'react';
import styles from './SliderNumber.module.scss';
import { Carousel as AntdCarousel, Col, Row } from "antd";
import "./styles.scss";
import { cdn } from 'core/cdn';
const NUMBER_PER_PAGE = 5


export const SliderNumber = ({ minValue = 0, maxValue = 10, defaultValue = 0, onChange }) => {
    const [value, setValue] = useState(defaultValue)
    const sliderRef = useRef(null)
    const onClick = (i) => {
        if (sliderRef) sliderRef.current.goTo(i - minValue)
    }
    const [items] = useState([...Array(maxValue - minValue)].map((_, i) => (<div id={`item item-${i + minValue}`} onClick={() => onClick(i + minValue)}><div>{i + minValue}</div></div>)))
    useEffect(() => {
        onChange(value)
    }, [value])


    useEffect(() => {
        if (defaultValue)
            if (sliderRef) sliderRef.current.goTo(defaultValue - minValue)
    }, [defaultValue])

    return (
        <div className='flex-center' style={{ width: "100%" }}>
            <div className={styles.SliderContainer} style={{ flex: "1" }}>
                <div className={styles.Mask}>
                </div>
                <Row >
                    <Col span={2} style={{ placeSelf: 'center' }}>
                        <img src={cdn('/images/vitals/carrousel_arrow_left.svg')} alt="arrow_left" />
                    </Col>
                    <Col span={20}>
                        <AntdCarousel
                            ref={sliderRef}
                            arrows={false}
                            dots={false}
                            className="slider-number"
                            swipeToSlide
                            infinite={true}
                            touchThreshold={10}
                            centerMode
                            afterChange={(e) => setValue(e + minValue)}
                            slidesToShow={NUMBER_PER_PAGE} >
                            {items}
                        </AntdCarousel>
                    </Col>
                    <Col span={2} style={{ placeSelf: 'center' }}>
                        <img src={cdn('/images/vitals/carrousel_arrow_right.svg')} alt="arrow_right" />
                    </Col>
                </Row >
            </div>
        </div>
    )
};


