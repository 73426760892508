import { AvailableDoctor, Doctor } from "types/entity"
import { Action, Message } from "types/redux"
import { DoctorsStore } from "types/store"
import {
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_ERROR,
  DOCTORS_RESET_STORE,
  GET_AVAILABLE_DOCTORS_ERROR,
  GET_AVAILABLE_DOCTORS_REQUEST,
  GET_AVAILABLE_DOCTORS_SUCCESS,
  RESEARCH_DOCTOR_SUCCESS,
} from "./constants"

const INITIATE_STORE: Partial<DoctorsStore> = {
  loading: false,
  success: false,
}
const reducer = function doctorsReducer(
  state = INITIATE_STORE,
  action: Action<Doctor[] | Message>
): Partial<DoctorsStore> {
  switch (action.type) {
    case GET_DOCTORS_REQUEST:
      return { ...state, loading: true, success: false }
    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        doctors: action.payload as AvailableDoctor[],
      }
    case GET_DOCTORS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload as Message,
      }
    case DOCTORS_RESET_STORE:
      return { ...INITIATE_STORE }

    case GET_AVAILABLE_DOCTORS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_AVAILABLE_DOCTORS_ERROR:
      return {
        ...state,
        loading: false,
      }
    case GET_AVAILABLE_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    case RESEARCH_DOCTOR_SUCCESS:
      return {
        ...state,
        researchDoctors: action.payload as Doctor[]
      }
    default:
      return { ...state }
  }
}

export default reducer
