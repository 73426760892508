import { ADD_VITALS, UNSET_VITALS_BY_TYPE_REDUCER } from "containers/Profile/services/constants"
import { VitalsStore } from "types/store"

export const initialState: Partial<VitalsStore> = {}

const reducer = function vitalsReducer(
  state = initialState,
  action: { type: string, payload: any, patient_id: number }
): Partial<VitalsStore> {
  const newState = { ...state }
  switch (action.type) {
    case ADD_VITALS: {
      return {
        ...newState,
        [action.patient_id]: {
          ...newState[action.patient_id],
          [action.payload.type]: {
            value: action.payload.value,
            id: action.payload.id,
          }
        }
      }
    }
    case UNSET_VITALS_BY_TYPE_REDUCER: {
      if(newState[action?.payload?.patient_id]?.[action?.payload?.type]) {
        delete newState[action.payload?.patient_id][action.payload?.type];
      }
      return newState;
    }
    default:
      return newState
  }
}

export default reducer
