import { omit } from "lodash"

import { SET_CALL } from "client/services/constants"
import { TLC_REQUEST, TLC_SUCCESS } from "containers/Payment/services/constants"
import { Call } from "types/payload"
import { Action, Message } from "types/redux"
import { CallStore } from "types/store"

import { CALL_CANCEL_ERROR, CALL_CANCEL_REQUEST, CALL_CANCEL_SUCCESS, CALL_ERROR, CALL_HANGUP_REQUEST, CALL_HAS_CHANGED, CALL_REQUEST, CALL_SUCCESS, JOIN_ROOM_EARLIER, JOIN_ROOM_EARLIER_ERROR, JOIN_ROOM_EARLIER_SUCCESS, REMOVE_CHOSEN_DOCTOR_ERROR, REMOVE_CHOSEN_DOCTOR_REQUEST, REMOVE_CHOSEN_DOCTOR_SUCCESS } from "./constants"

const initialState: Partial<CallStore> = {
  success: false,
  loading: true,
}

const reducer = function callReducer(
  state = initialState,
  action: Action<Call | Message | { call: Call }>
): Partial<CallStore> {
  let newState = { ...state }
  switch (action.type) {
    case CALL_REQUEST:
    case CALL_HANGUP_REQUEST:
    case CALL_CANCEL_REQUEST:
      return { ...newState, loading: true, message: undefined }
    case CALL_SUCCESS:
    case TLC_REQUEST:
      return { ...state, message: undefined, successDeleteDoctor: false }
    case TLC_SUCCESS:
      newState = {
        ...state,
        success: true,
        loading: false,
        call: action.payload as Call,
      }
      return newState
    case CALL_CANCEL_SUCCESS:
      newState = {
        ...state,
        success: true,
        loading: false,
        call: { ...(action.payload as { call }).call },
        message: {
          ...(omit(action.payload, "call") as Message),
        },
      }
      return newState
    case CALL_CANCEL_ERROR:
      newState = {
        ...state,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
      return newState
    case CALL_ERROR:
      return {
        ...newState,
        loading: false,
        success: false,
        message: action.payload as Message,
      }
    case REMOVE_CHOSEN_DOCTOR_REQUEST:
      return {
        ...state,
        loadingDeleteDoctor: true,
      }
    case REMOVE_CHOSEN_DOCTOR_ERROR:
      return {
        ...state,
        loadingDeleteDoctor: false,
      }
    case REMOVE_CHOSEN_DOCTOR_SUCCESS:
      return {
        ...state,
        loadingDeleteDoctor: false,
        successDeleteDoctor: true,
        message: { type: "success", text: "Votre temps d'attente a été réduit." }
      }
    case JOIN_ROOM_EARLIER_SUCCESS:
    case JOIN_ROOM_EARLIER_ERROR:
      return {
        ...state,
        loading: false,
      }
     case JOIN_ROOM_EARLIER: 
      return {
        ...state, loading:true
      } 
    case SET_CALL:
      return {
        ...newState,
        successDeleteDoctor: false,
        loading: false,
        call: action.payload as Call,
        message: undefined,
      }
  }
  return newState
}

export default reducer
