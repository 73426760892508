import React, { Component, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

import AnimatedLogo from "components/Logo/AnimatedLogo"
import { callHasChanged } from "containers/Call/services/actions"
import { logout } from "containers/Login/services/actions"
import actionsPayment from "containers/Payment/services/actions"
import { Answer } from "containers/Survey/types"
import {
  FINALE_PAGE,
  HOME_PAGE,
  PAYMENT_PAGE,
  PROFILE_PAGE,
  SURVEY_PAGE,
} from "core/constants"
import { CallState } from "types/payload"
import { Action, ActionWithoutPayload } from "types/redux"
import { CallStore, SurveyStore } from "types/store"

import { ConnectParams } from "./contexts/videoContext"
import LobbyCall from "./LobbyCall"
import {
  callHangupRequest,
  cancelCallRequest,
  getCall,
} from "./services/actions"
import { connectRoom } from "./services/video/actions"
import VideoCall from "./VideoCall"
import Message from "components/Message/Message"
import { omit } from "lodash"

const Call = (props: CallStore & { answers: Answer[] }): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    props.getCurrentCall()
  }, [])

  const relaunch = () => {
    dispatch(callHasChanged(undefined))
    navigate(SURVEY_PAGE)
  }

  if (props.loading) return <AnimatedLogo width={500} height={300} />
  else if (props.call)
    switch (props.call.state) {
      case CallState.ENDED:
        return <Navigate to={FINALE_PAGE} replace />
      case CallState.WAITING_ROOM:
      case CallState.STARTED:
        return <VideoCall {...omit(props, "answers")} />
      default:
        return <LobbyCall {...omit(props, "answers")} createCall={relaunch} />
    }
  else return <Navigate to={PROFILE_PAGE} replace />
}

const mapStateToProps = ({
  call,
  survey,
}: {
  call: CallStore
  survey: SurveyStore
}): CallStore & { answers: Answer[] } => {
  return { ...call, answers: survey.answers }
}

const mapDispatchToProps = (
  dispatch: (e: Action<unknown> | ActionWithoutPayload) => void
): Partial<CallStore> => {
  return {
    hangup: () => dispatch(callHangupRequest()),
    connect: (params: ConnectParams) => dispatch(connectRoom(params)),
    cancelCall: () => {
      dispatch(cancelCallRequest())
    },
    createCall: (r: Answer[]) => dispatch(actionsPayment.submit(r)),
    getCurrentCall: () => dispatch(getCall()),
    logout: () => dispatch(logout()),
  } as Partial<CallStore>
}

export default connect(mapStateToProps, mapDispatchToProps)(Call)
