import { cdn } from "core/cdn"
import {
  CONSENT_PAGE,
  DOCTOR_CHOICE_PAGE,
  GET_DOCTOR_PAGE,
  INSERT_RELATIVE_PAGE,
  NIR_AND_EXEMPTION_PAGE,
  UPLOAD_DOC_PAGE,
  VITALS_PAGE,
} from "core/constants"

import { ACTION, Question, QUESTION_TYPE, Reply, UiView } from "./types"
import { languages } from "locales/languages"

export const DOCTOR_AVAILABLE = {
  EXISTS_BUT_UNAVAILABLE: true,
  DOES_NOT_EXIST: false,
}

export const ITEMS_PER_CAROUSEL_PAGE = 6
export const SECOND_NEXT_ANSWER = 0.2
export const PATH_DEEP = ":"
export const DURATION_SLOT_MIN = 5
export const NUMBER_OF_SLOTS_BEFORE_NOW = 5
export const NUMBER_OF_SLOTS_AFTER_NOW = 5

export enum PATH {
  patient = "patient",
  relative = "patient:relative",
  exemption = "exemption",
  doctor = "doctor",
  slot = "doctor:slot",
  inform = "inform",
  reason = "reason",
  vitals = "reason:vitals",
  illness = "illness",
  illness_details = "illness:details",
  allergy = "allergy",
  allergy_details = "allergy:details",
  operation = "operation",
  drug = "drug",
  pregnant = "pregnant",
  ineligible = "ineligible",
  consent = "consent",
  choice_of_doctor = "choice_of_doctor",
  doctor_available = "doctor_available",
  upload_documents = "upload_documents",
  selected_documents = "upload_documents:redirect",
  selected_carer = "selected_carer",
  // upload_documents_redirect = "selected_documents:redirect",
}

const booleanSubReplies: Reply[] = [
  {
    title: languages.yes,
    action: ACTION.subquestion,
    value: true,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.no,
    value: false,
    ui: {
      view: "button",
    },
  },
]

const booleanReplies: Reply[] = [
  {
    title: languages.yes,
    value: true,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.no,
    value: false,
    ui: {
      view: "button",
    },
  },
]

export const consent: Question = {
  id: PATH.consent,
  title: "do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: CONSENT_PAGE,
}

export const doctor_available: Question = {
  title: languages.anamneseDoctorAvailableQuestion,
  // imgUrl: cdn("/images/anamnese_illustration.svg"),
  id: PATH.doctor_available,
  multipleReplies: false,
  replies: [
    {
      title: languages.doNotHaveDoctor,
      value: DOCTOR_AVAILABLE.DOES_NOT_EXIST,
    },
    {
      title: languages.doctorNotAvailable,
      value: DOCTOR_AVAILABLE.EXISTS_BUT_UNAVAILABLE,
    },
  ],
}

export const choice_of_doctor: Question = {
  title: languages.anamneseChoiceOfDoctor,
  id: PATH.choice_of_doctor,
  type: QUESTION_TYPE.redirect,
  replies: [],
  value: DOCTOR_CHOICE_PAGE,
}

const ineligible_reason: Question = {
  title: languages.uneligibleTitle,
  id: PATH.ineligible,
  informationText: languages.ineligibleReasonInformation,
  multipleReplies: false,
  replies: [
    {
      imgUrl: cdn("/images/ineligible/picto_bebe.svg"),
      title: languages.lessthan3yearOld,
      value: "Les enfants de moins de 3 ans",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_ado.svg"),
      title: languages.unaccompaniedMinors,
      value: "Les mineurs non accompagnés",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_prolongation_arret_de_travail.svg"),
      title: languages.ExtendedSickLeave,
      value: "Prolongation d'arrêt de travail",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_certificat_sport.svg"),
      title: languages.fitnessCertificate,
      value: "Certificat d'aptitude (sport, crèche)",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_ordo.svg"),
      title: languages.PrescriptionOfPsychoactive,
      value: "Prescription de traitements psychoactifs de dépendance",
      action: ACTION.nothing,
    },
  ],
  defaultReply: {
    title: languages.IhaveUnderstood,
    value: true,
    ui: { type: "primary" },
  },
  ui: { view: "inline" as UiView },
}

export const reasonValues = {
  COVID: "Coronavirus (Covid 19)",
  ALLERGIES: "Allergies",
  BURN: "Brûlure",
  CONTRACEPTION: "Contraception",
  CYSTITIS: "Cystite",
  JOINT_PAIN: "Douleurs articulaires",
  TOOTH_PAIN: "Douleurs dentaires",
  DIARRHEA_OR_VOMITING: "Diarrhée ou vomissement",
  BACK_PAIN: "Douleurs lombaires",
  RASH: "Éruption cutanée",
  TIRED: "Fatigue / anxiété / stress",
  FEVER: "Fièvre",
  SEXUAL_INFECTION: "Infection sexuelle",
  SORE_THROAT: "Maux de gorge",
  HEADACHE: "Maux de tête",
  STOMACH_ACHE: "Maux de ventre",
  EYE_PAIN: "Œil rouge et/ou douleur",
  STING: "Piqûre / morsure / blessure",
  OTITIS: "Oreilles/otite",
  PRESCRIPTION_REFILL: "Renouvellement d'ordonnance",
  PERIOD_PAIN: "Règles douloureuses",
  LAB_RESULTS: "Résultats de laboratoire",
  COLD_AND_FLU: "Rhume et syndrome grippal",
  SINUS: "Sinus ou nez bouché",
  COUGH: "Toux",
  OTHER_REASON: "Autre",
}

export const vitals: Question = {
  id: PATH.vitals,
  title: "do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  value: VITALS_PAGE,
  type: QUESTION_TYPE.redirect,
}

const reason: Question = {
  title: languages.anamneseReasonQuestion,
  id: PATH.reason,
  multipleReplies: false,
  ui: { view: "carousel" },
  replies: [
    {
      title: languages.sore_throat,
      value: reasonValues.SORE_THROAT,
      imgUrl: cdn("/images/reasons/picto_mal_de_gorge.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.diarrhea_or_vomiting,
      value: reasonValues.DIARRHEA_OR_VOMITING,
      imgUrl: cdn("/images/reasons/picto_diarrhee.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.cold_and_flu,
      value: reasonValues.COLD_AND_FLU,
      imgUrl: cdn("/images/reasons/picto_rhume.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.fever,
      value: reasonValues.FEVER,
      imgUrl: cdn("/images/reasons/picto_fievre.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.cystitis,
      value: reasonValues.CYSTITIS,
      imgUrl: cdn("/images/reasons/picto_cystite.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.prescription_refill,
      value: reasonValues.PRESCRIPTION_REFILL,
      imgUrl: cdn("/images/reasons/picto_ordonnance.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.jointpain,
      value: reasonValues.JOINT_PAIN,
      imgUrl: cdn("/images/reasons/picto_jointpain.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.otitis,
      value: reasonValues.OTITIS,
      imgUrl: cdn("/images/reasons/picto_otitis.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.cough,
      value: reasonValues.COUGH,
      imgUrl: cdn("/images/reasons/picto_toux.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.headache,
      value: reasonValues.HEADACHE,
      imgUrl: cdn("/images/reasons/picto_mal_de_tete.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.back_pain,
      value: reasonValues.BACK_PAIN,
      imgUrl: cdn("/images/reasons/picto_douleur_lombaire.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.tired,
      value: reasonValues.TIRED,
      imgUrl: cdn("/images/reasons/picto_fatigue.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.rash,
      value: reasonValues.RASH,
      imgUrl: cdn("/images/reasons/picto_eruption_cutanee.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.stomach_ache,
      value: reasonValues.STOMACH_ACHE,
      imgUrl: cdn("/images/reasons/picto_maux_de_ventre.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.allergy,
      value: reasonValues.ALLERGIES,
      imgUrl: cdn("/images/reasons/picto_allergies.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.toothpain,
      value: reasonValues.TOOTH_PAIN,
      imgUrl: cdn("/images/reasons/picto_toothache.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.eye_pain,
      value: reasonValues.EYE_PAIN,
      imgUrl: cdn("/images/reasons/picto_oeil_rouge_douleur.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.sexual_infection,
      value: reasonValues.SEXUAL_INFECTION,
      imgUrl: cdn("/images/reasons/picto_infection_sexuelle.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.sinus,
      value: reasonValues.SINUS,
      imgUrl: cdn("/images/reasons/picto_nez_bouche.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.period_pain,
      value: reasonValues.PERIOD_PAIN,
      imgUrl: cdn("/images/reasons/picto_regles_douloureuse.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.sting,
      value: reasonValues.STING,
      imgUrl: cdn("/images/reasons/picto_sting.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.contraception,
      value: reasonValues.CONTRACEPTION,
      imgUrl: cdn("/images/reasons/picto_contraception.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.lab_results,
      value: reasonValues.LAB_RESULTS,
      imgUrl: cdn("/images/reasons/picto_resultat_laboratoire.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.burn,
      value: reasonValues.BURN,
      imgUrl: cdn("/images/reasons/picto_brulure.svg"),
      action: ACTION.subquestion
    },
    {
      title: languages.otherReason,
      value: reasonValues.OTHER_REASON,
      imgUrl: cdn("/images/reasons/picto_autre.svg"),
      action: ACTION.subquestion
    },
  ],
  subquestion: vitals,
}

const relative: Question = {
  title: languages.questionWhichRelative,
  // ui: { view: "checkbox" },
  id: PATH.relative,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    title: languages.addARelative,
    value: INSERT_RELATIVE_PAGE,
    action: ACTION.redirect,
  },
}

const exemption: Question = {
  id: PATH.exemption,
  title: "do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: NIR_AND_EXEMPTION_PAGE,
}

const selected_documents: Question = {
  id: PATH.selected_documents,
  title: languages.uploadDocumentSelectOrAdd,
  multipleReplies: true,
  imgUrl: "",
  replies: [],
  defaultReply: {
    title: languages.uploadDocumentAddButton,
    action: ACTION.redirect,
    value: UPLOAD_DOC_PAGE,
  },
}

const upload_documents: Question = {
  id: PATH.upload_documents,
  title: languages.uploadDocumentQuestion,
  subtitle: languages.uploadDocumentTypesHints,
  imgUrl: cdn("images/document_send_illustration.svg"),
  multipleReplies: false,
  replies: booleanSubReplies,
  subquestion: selected_documents,
}

const inform_anamnesis: Question = {
  title: languages.inform_anamnesis,
  imgUrl: cdn("/images/anamnese_illustration.svg"),
  id: PATH.inform,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    ui: { type: "primary" },
    title: languages.continue,
    value: -1,
  },
}

const patient: Question = {
  title: languages.questionForWho,
  id: PATH.patient,
  subquestion: relative,
  multipleReplies: false,
  replies: [],
}

export const pregnant: Question = {
  imgUrl: cdn("/images/enceinte_illustration.svg"),
  title: languages.anamnesePregnantQuestion,
  id: PATH.pregnant,
  multipleReplies: false,
  replies: booleanReplies,
}

const operation: Question = {
  imgUrl: cdn("/images/operation_illustration.svg"),
  title: languages.anamneseOperationsQuestion,
  id: PATH.operation,
  multipleReplies: false,
  replies: booleanReplies,
}

const drug: Question = {
  imgUrl: cdn("/images/medicament_illustration.svg"),
  title: languages.anamneseDrugsQuestion,
  id: PATH.drug,
  multipleReplies: false,
  replies: booleanSubReplies,
}
const allergy_details: Question = {
  title: languages.anamneseAllergiesQuestionDetails,
  id: PATH.allergy_details,
  // ui: { view: "checkbox" },
  multipleReplies: true,
  replies: [
    {
      title: languages.penicillinAllergy,
      value: "allergie à la pénicilline",
      imgUrl: cdn("/images/allergies/picto_medicament.svg"),
    },
    {
      title: languages.drugAllergy,
      value: "allergie aux médicaments",
      imgUrl: cdn("/images/allergies/picto_medicament.svg"),
    },
    {
      title: languages.pollenAllergy,
      value: "allergie au pollen",
      imgUrl: cdn("/images/allergies/picto_pollen.svg"),
    },
    {
      title: languages.foodAllergy,
      value: "allergie alimentaire",
      imgUrl: cdn("/images/allergies/picto_alimentaire.svg"),
    },
    {
      title: languages.otherAllergy,
      value: "autre",
      imgUrl: cdn("/images/allergies/picto_autre.svg"),
    },
  ],
}

const allergy: Question = {
  imgUrl: cdn("/images/allergie_illustration.svg"),
  title: languages.anamneseAllergiesQuestion,
  subtitle: languages.anamneseAllergiesQuestionSubtitle,
  id: PATH.allergy,
  multipleReplies: false,
  subquestion: allergy_details,
  replies: booleanSubReplies,
}

const illness_details: Question = {
  title: languages.anamneseIllnessQuestionDetails,
  id: PATH.illness_details,
  multipleReplies: true,
  // ui: { view: "checkbox" },
  replies: [
    {
      title: languages.diabetes,
      value: "diabète",
      imgUrl: cdn("/images/illnesses/picto_diabete.svg"),
    },
    {
      title: languages.hypertension,
      value: "hypertension",
      imgUrl: cdn("/images/illnesses/picto_hypertension.svg"),
    },
    {
      title: languages.cancer,
      value: "cancer",
      imgUrl: cdn("/images/illnesses/picto_cancer.svg"),
    },
    {
      title: languages.respiratoryDisease,
      value: "maladie respiratoire",
      imgUrl: cdn("/images/illnesses/picto_respiration.svg"),
    },
    {
      title: languages.heartDisease,
      value: "maladie cardiaque",
      imgUrl: cdn("/images/illnesses/picto_cardiaque.svg"),
    },
    {
      title: languages.kidneyDisease,
      value: "maladie rénale",
      imgUrl: cdn("/images/illnesses/picto_renal.svg"),
    },
    {
      title: languages.digestiveDisease,
      value: "maladie digestive",
      imgUrl: cdn("/images/illnesses/picto_digestion.svg"),
    },
    {
      title: languages.infectiousDisease,
      value: "maladie infectieuse",
      imgUrl: cdn("/images/illnesses/picto_infection.svg"),
    },
    {
      title: languages.otherDisease,
      value: "autre",
      imgUrl: cdn("/images/allergies/picto_autre.svg"),
    },
  ],
}

const illness: Question = {
  imgUrl: cdn("/images/maladie_chronique_illustration.svg"),
  title: languages.anamneseIllnessQuestion,
  subtitle: languages.anamneseIllnessQuestionSubtitle,
  id: PATH.illness,
  subquestion: illness_details,
  multipleReplies: false,
  replies: booleanSubReplies,
}

export const QUESTIONS = [
  patient,
  exemption,
  inform_anamnesis,
  reason,
  illness,
  operation,
  allergy,
  pregnant,
  drug,
  upload_documents,
  ineligible_reason,
  doctor_available,
  choice_of_doctor,
]

// JSON SCHEMA
export const surveyLanguages = [
  {
    id: PATH.reason,
    patient: {
      title: languages.anamneseReasonQuestion,
    },
    relative: {
      male: { title: languages.anamneseReasonQuestion_relative_male },
      female: { title: languages.anamneseReasonQuestion_relative_female },
    },
  },

  {
    id: PATH.patient,
    patient: {
      title: languages.questionForWho,
    },
    relative: {
      male: { title: languages.questionForWho },
      female: { title: languages.questionForWho },
    },
  },
  {
    id: PATH.doctor,
    patient: {
      title: languages.orderForWhichDoctor,
      defaultReply: languages.noAppointment,
    },
    relative: {
      male: {
        title: languages.orderForWhichDoctor_relative_male,
        defaultReply: languages.noAppointment_relative_male,
      },
      female: {
        title: languages.orderForWhichDoctor_relative_female,
        defaultReply: languages.noAppointment_relative_female,
      },
    },
  },
  {
    id: PATH.slot,
    patient: {
      title: languages.questionWhichTime,
    },
    relative: {
      male: { title: languages.questionWhichTime },
      female: { title: languages.questionWhichTime },
    },
  },
  {
    id: PATH.pregnant,
    patient: {
      title: languages.anamnesePregnantQuestion,
    },
    relative: {
      male: { title: languages.anamnesePregnantQuestion_relative_female },
      female: { title: languages.anamnesePregnantQuestion_relative_female },
    },
  },
  {
    id: PATH.operation,
    patient: {
      title: languages.anamneseOperationsQuestion,
    },
    relative: {
      male: { title: languages.anamneseOperationsQuestion_relative_male },
      female: { title: languages.anamneseOperationsQuestion_relative_female },
    },
  },
  {
    id: PATH.doctor_available,
    patient: {
      title: languages.anamneseDoctorAvailableQuestion,
    },
    relative: {
      male: { title: languages.anamneseDoctorAvailableQuestion_relative_male },
      female: {
        title: languages.anamneseDoctorAvailableQuestion_relative_female,
      },
    },
  },
  {
    id: PATH.drug,
    patient: {
      title: languages.anamneseDrugsQuestion,
    },
    relative: {
      male: { title: languages.anamneseDrugsQuestion_relative_male },
      female: { title: languages.anamneseDrugsQuestion_relative_female },
    },
  },
  {
    id: PATH.upload_documents,
    patient: {
      title: languages.uploadDocumentQuestion,
    },
    relative: {
      male: { title: languages.uploadDocumentQuestion },
      female: { title: languages.uploadDocumentQuestion },
    },
  },
  {
    id: PATH.allergy,
    patient: {
      title: languages.anamneseAllergiesQuestion,
      subtitle: languages.anamneseAllergiesSubtitle,
    },
    relative: {
      male: {
        title: languages.anamneseAllergiesQuestion_relative_male,
        subtitle: languages.anamneseAllergiesSubtitle,
      },
      female: {
        title: languages.anamneseAllergiesQuestion_relative_female,
        subtitle: languages.anamneseAllergiesSubtitle,
      },
    },
  },
  {
    id: PATH.illness,
    patient: {
      title: languages.anamneseIllnessQuestion,
      subtitle: languages.anamneseIllnessSubtitle,
    },
    relative: {
      male: {
        title: languages.anamneseIllnessQuestion_relative_male,
        subtitle: languages.anamneseIllnessSubtitle,
      },
      female: {
        title: languages.anamneseIllnessQuestion_relative_female,
        subtitle: languages.anamneseIllnessSubtitle,
      },
    },
  },
  {
    id: PATH.inform,
    patient: {
      title: languages.inform_anamnesis,
    },
    relative: {
      male: { title: languages.inform_anamnesis_relative_male },
      female: { title: languages.inform_anamnesis_relative_female },
    },
  },
]

// /!\ DO NOT TRANSLATE THE REASON KEY VALUE IN THIS OBJECT /!\
export const subTitles = [
  { reason: "Coronavirus (Covid 19)", subtitle: languages.uploadDocumentTypesHints },
  { reason: "Allergies", subtitle: languages.uploadDocumentTypesHints },
  { reason: "Brûlure", subtitle: languages.photoOfBurn },
  {
    reason: "Contraception",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Cystite", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Douleurs articulaires",
    subtitle: languages.photoOfJoins,
  },
  { reason: "Douleurs dentaires", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Diarrhée ou vomissement",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Douleurs lombaires", subtitle: languages.uploadDocumentTypesHints },
  { reason: "Éruption cutanée", subtitle: languages.photoOfLesion },
  { reason: "Fatigue / anxiété / stress", subtitle: languages.uploadDocumentTypesHints },
  { reason: "Fièvre", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Infection sexuelle",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Maux de gorge", subtitle: languages.photoOfThroat },
  { reason: "Maux de tête", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Maux de ventre",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Œil rouge et/ou douleur", subtitle: languages.photoOfTheEye },
  { reason: "Piqûre / morsure / blessure", subtitle: languages.photoOfSting },
  { reason: "Oreilles/otite", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Renouvellement d'ordonnance",
    subtitle: languages.PrescriptionToBeRenewed,
  },
  {
    reason: "Règles douloureuses",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Résultats de laboratoire", subtitle: languages.analysisResults },
  {
    reason: "Rhume et syndrome grippal",
    subtitle: languages.uploadDocumentTypesHints,
  },
  { reason: "Sinus ou nez bouché", subtitle: languages.uploadDocumentTypesHints },
  { reason: "Toux", subtitle: languages.uploadDocumentTypesHints },
  {
    reason: "Autre",
    subtitle: languages.uploadDocumentTypesHints,
  },
]
