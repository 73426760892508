// CALL
export const CALL_REQUEST = "CALL_REQUEST"
export const CALL_SUCCESS = "CALL_SUCCESS"
export const CALL_ERROR = "CALL_ERROR"
export const CALL_HAS_CHANGED = "CALL_HAS_CHANGED"

export const CALL_JUST_STARTED = "CALL_JUST_STARTED"
export const GET_CALL = "GET_CALL"

export const CALL_HANGUP_REQUEST = "CALL_HANGUP_REQUEST"
export const CALL_HANGUP_SUCCESS = "CALL_HANGUP_SUCCESS"
export const CALL_HANGUP_ERROR = "CALL_HANGUP_ERROR"

export const CALL_CANCEL_REQUEST = "CALL_CANCEL_REQUEST"
export const CALL_CANCEL_SUCCESS = "CALL_CANCEL_SUCCESS"
export const CALL_CANCEL_ERROR = "CALL_CANCEL_ERROR"

export const REMOVE_CHOSEN_DOCTOR_REQUEST = "REMOVE_CHOSEN_DOCTOR_REQUEST"
export const REMOVE_CHOSEN_DOCTOR_SUCCESS = "REMOVE_CHOSEN_DOCTOR_SUCCESS"
export const REMOVE_CHOSEN_DOCTOR_ERROR = "REMOVE_CHOSEN_DOCTOR_ERROR"
export const JOIN_ROOM_EARLIER = "JOIN_ROOM_EARLIER"
export const JOIN_ROOM_EARLIER_SUCCESS = "JOIN_ROOM_EARLIER_SUCCESS"
export const JOIN_ROOM_EARLIER_ERROR = "JOIN_ROOM_EARLIER_ERROR"
export const THREE_MINUTES = 3
