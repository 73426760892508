import config from "react-global-configuration"
import { eventChannel } from "redux-saga"
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects"

import { request } from "lib/request"
import { Call, CallState, ResponseCall } from "types/payload"
import { GFlow, GWatcher, Message } from "types/redux"

import {
  callError,
  callHangupError,
  callHangupSuccess,
  callHasChanged,
  cancelCallError,
  cancelCallSuccess,
} from "./actions"
import { JOIN_ROOM_EARLIER_SUCCESS, JOIN_ROOM_EARLIER, JOIN_ROOM_EARLIER_ERROR, CALL_CANCEL_REQUEST, CALL_HANGUP_REQUEST, GET_CALL, REMOVE_CHOSEN_DOCTOR_ERROR, REMOVE_CHOSEN_DOCTOR_REQUEST, REMOVE_CHOSEN_DOCTOR_SUCCESS } from "./constants"
import { languages } from "locales/languages"

export async function getCurrentCallApi(): Promise<ResponseCall> {
  return request<ResponseCall>(config.get("call.get.current"), {
    method: "GET",
  })
}

async function hangupCallApi() {
  return request(config.get("call.hangup"), {
    method: "POST",
  })
}

async function cancelCallApi() {
  return request(config.get("call.get.current"), {
    method: "DELETE",
  })
}

function* hangupCallFlow(): GFlow<ResponseCall | Message | Call> {
  try {
    const hangupResponse: ResponseCall = yield call(hangupCallApi)
    yield all([
      put(callHangupSuccess()),
      put(callHasChanged(hangupResponse.call)),
    ])
  } catch (e) {
    console.error(e, { route: config.get("call.hangup") })
    yield put(callHangupError(e as string))
  }
}
let timed = false
function countdown(counter: number, interval: number) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(counter++)
    }, interval)
    return () => {
      clearInterval(iv)
    }
  })
}

function* currentCallFlow(): GFlow<{ call?: Call }> {
  try {
    const response = yield call(getCurrentCallApi)
    // Si le timer est activé, on envoie simplement la response qui devrait arriver entre 2 interval
    // notament l'appel pour s'avoir si le docteur à raccrocher après un particiant.disconnected
    if (response.call && timed === false) yield sagaInterval(response.call)
    else yield put(callHasChanged(response.call))
  } catch (e) {
    console.warn("[TLC Receiver Error] Cannot get TLC", e)
    console.error(e, { route: config.get("call.get.current") })
    // yield put(callError(""))
    // Relance de l'interval de récupération de call en cas d'erreur
    // après un petit délai
    yield delay(config.get("timeout.call_interval"))
    return yield currentCallFlow()
  }
}


function getIntervalByEta(eta: number | null) {
  if (eta < 10) {
    return 5000;
  }
  if (eta >= 10) {
    return 15000;
  }
  if (eta >= 30) {
    return 30000;
  }
  return 5000;
}

let lastEta = 0;

export async function putCurrentCallApi(): Promise<ResponseCall> {
  return request<ResponseCall>(config.get("call.put.current"), {
    method: "PUT",
  })
}

export function* sagaInterval(currentCall: Call): GFlow<{ call?: Call }> {
  // On rentre dans une gestion autonome de l'appel à /current/call
  // Si l'API nous fourni un call, on va activer le timer (timed === true)
  // et chaque 10 secondes va faire un appel /current/call jusqu'à ce qu'il soit null
  timed = true
  yield put(callHasChanged(currentCall))
  const interval = getIntervalByEta(currentCall?.eta?.computed);
  const chan = yield call(countdown, 0, interval);
  try {
    while (true) {
      yield take(chan)
      const responseCallApi = yield call(getCurrentCallApi)
      if (!responseCallApi?.call) {
        // Le dernier non-call reçu est celui qui mais final_state à true
        const lastCall = yield select(({ client }) => client.call)
        if (lastCall && stepOnGoing.includes(lastCall.state)) {
          // le dernier call reçu est ongoing
          // Visiblement nous n'avons pas reçu le final_state
          yield all([
            put(
              callError(
                "Il semble qu'une demande de téléconsultation soit déjà lancée sur un autre onglet.",
                0
              )
            ),
            put(callHasChanged(undefined)),
          ])
        }
        chan.close()
      } else if ([CallState.INITIALIZED, CallState.WAITING_ROOM].includes(responseCallApi.call.state)) {
        /**
         * if we are waiting for a call and eta changed we restart new interval after an eta interval delay
         */
        if (responseCallApi?.call?.eta?.computed !== lastEta) {
          timed = false;
          chan.close();
          lastEta = responseCallApi.call.eta.computed;
          yield delay(interval);
          return yield currentCallFlow()
        }
        lastEta = responseCallApi.call.eta.computed;
      } else if (stepStop.includes(responseCallApi.call.state)) {
        // Si le call est terminé ou annulé
        yield all([put(callHasChanged(responseCallApi.call))])
        chan.close()
      } else {
        yield all([put(callHasChanged(responseCallApi.call))])
      }
    }
  } catch (error) {
    console.warn("[TLC Receiver Error] Cannot get TLC", error)
    console.error(error, {
      route: "call::sagaInterval"
    })
    // 401 // 429
    // yield put(callError(""))
    // Si nous ne parvenons pas à récupérer la TLC courante,
    // on relance le sagaInterval par l'intermediaire de currentCallFlow
    timed = false
    yield delay(config.get("timeout.call_interval"))
    return yield currentCallFlow()
  } finally {
    timed = false
  }
}
const stepStop: CallState[] = [
  CallState.ENDED,
  CallState.CANCELLED_BY_DOCTOR,
  CallState.CANCELLED_BY_PATIENT,
  CallState.EXPIRED,
]
const stepOnGoing: CallState[] = [
  CallState.INITIALIZED,
  CallState.WAITING_ROOM,
  CallState.HUNGUP,
  CallState.ACCEPTED,
  CallState.STARTED,
]

function* cancelCallFlow(): GFlow<Message & { call: Call }> {
  try {
    const response: ResponseCall = yield call(cancelCallApi)
    yield all([
      put(cancelCallSuccess({ call: response.call, message: languages.callHasBeenCancelled })),
      put(callHasChanged(response.call)),
    ])
  } catch (error) {
    console.error(error, {
      route: config.get("call.get.current")
    })

    yield put(cancelCallError(error as string))
  }
}
function* removeDoctorFlow(): any {
  try {
    const response = yield request("/calls/current/chosen_doctor", { method: "DELETE" })
    yield put({ type: REMOVE_CHOSEN_DOCTOR_SUCCESS })
  } catch (e) {
    yield put({ type: REMOVE_CHOSEN_DOCTOR_ERROR })
  }
}

function* joinWaitingRoomFlow(): GFlow<{ call?: Call }> {
  try {
    const response = yield call(putCurrentCallApi)
    if ((response as ResponseCall).call) yield all([put(callHasChanged((response as ResponseCall).call)), put({type: JOIN_ROOM_EARLIER_SUCCESS})])
  } catch (e) {
    yield put({type: JOIN_ROOM_EARLIER_ERROR})
    console.warn("[JOIN ROOM EARLIER] Cannot get Patient to room Earlier", e)
    console.error(e, { route: config.get("call.put.current") })
  }
}

function* callWatcher(): GWatcher {
  yield takeLatest(GET_CALL, currentCallFlow)
  yield takeLatest(CALL_HANGUP_REQUEST, hangupCallFlow)
  yield takeLatest(CALL_CANCEL_REQUEST, cancelCallFlow)
  yield takeLatest(REMOVE_CHOSEN_DOCTOR_REQUEST, removeDoctorFlow)
  yield takeLatest(JOIN_ROOM_EARLIER, joinWaitingRoomFlow)
}

export default callWatcher
