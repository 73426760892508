import { Component, ReactNode } from "react"
import { connect } from "react-redux"
import {
  GuardConfigProvider,
  GuardedRoute,
  GuardedRouteProps,
  GuardedRoutes,
  GuardMiddleware,
  GuardProvider,
  NextFunction,
} from "react-router-guarded-routes"

import client from "client/services/actions"
import {
  Admin,
  Call,
  Finale,
  Home,
  InsertRelative,
  Loading,
  Lobby,
  Login,
  NirAndExemption,
  NotAuthorized,
  NotFound,
  Payment,
  Profile,
  Signup,
  Statistics,
  Survey,
  Upload,
  VerifyPhone,
} from "containers"
import Consent from "containers/Consent/Consent"
import Doctors from "containers/Doctors/Doctors"
import NoNetwork from "containers/Errors/NoNetwork/NoNetwork"
import SetRelativeGender from "containers/InsertRelative/setRelativeGender"
import ModifyRelative from "containers/Profile/ModifyRelative"
import ResetPassword from "containers/ResetPassword/ResetPassword"
import { Tutorial } from "containers/Tutorial/Index"
import { CallState } from "types/payload"
import { TutoState } from "types/props"
import { Dispatcher } from "types/redux"
import { ClientStore, ConfigurationStore } from "types/store"

import {
  ADMIN_PAGE,
  ADMIN_CARERS_PAGE,
  CALL_PAGE,
  CONSENT_PAGE,
  FINALE_PAGE,
  GET_DOCTOR_PAGE,
  HOME_PAGE,
  INSERT_RELATIVE_PAGE,
  LOBBY_PAGE,
  LOGIN_PAGE,
  MODIFY_RELATIVE_PAGE,
  NIR_AND_EXEMPTION_PAGE,
  NO_NETWORK_PAGE,
  NOT_AUTHORIZED_PAGE,
  PAYMENT_PAGE,
  PROFILE_PAGE,
  REGISTER_PAGE,
  RESET_PASSWORD_PAGE,
  SET_RELATIVE_PAGE,
  STATISTICS_PAGE,
  SURVEY_PAGE,
  TUTORIALS_PAGE,
  UPLOAD_DOC_PAGE,
  VERIFY_PHONE_PAGE,
  DOCTOR_CHOICE_PAGE,
  VITALS_PAGE,
  MAINTENANCE_PAGE,
} from "./constants"
import {
  GUARD_IS_ADMIN,
  GUARD_IS_LOGGED_IN,
  GUARD_IS_NOT_ADMIN,
  GUARD_IS_NOT_LOGGED_IN,
  GUARD_IS_NOT_VERIFIED,
  GUARD_IS_VERIFIED,
} from "./guards"
import { AdminCarer } from "containers/Carer/Carer"
import { DoctorChoice } from "containers/Doctors/DoctorChoice/DoctorChoice"
import { Vitals } from "containers/Vitals/Vitals"
import Maintenance from "containers/Maintenance/Maintenance"

interface RootProps {
  fetchInstallation: () => void
  success: boolean
  admin: boolean
  login: boolean
  loading: boolean
  verified: boolean
  call: boolean
  maintenance?: boolean
}

class Root extends Component<RootProps> {
  state = { shouldRefresh: false }

  constructor(props: RootProps) {
    super(props)
    this.props.fetchInstallation()
  }

  private requireGuards = async (
    to: { location; matches; route },
    from: GuardedRouteProps | null,
    next: NextFunction<any>,
    guardValue
  ) => {
    if (this.props.call) next(CALL_PAGE, { replace: true })
    switch (guardValue) {
      case GUARD_IS_ADMIN:
        this.props.admin ? next() : next(ADMIN_PAGE, { replace: true })
        break
      case GUARD_IS_LOGGED_IN:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (!this.props.login) next(LOBBY_PAGE, { replace: true })
        else next()
        break
      case GUARD_IS_VERIFIED:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (!this.props.login) {
          next(LOBBY_PAGE, { replace: true })
        } else if (!this.props.verified)
          next(VERIFY_PHONE_PAGE, { replace: true })
        else next()
        break
      case GUARD_IS_NOT_VERIFIED:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (!this.props.login) next(LOBBY_PAGE, { replace: true })
        else if (this.props.verified) next(PROFILE_PAGE, { replace: true })
        else next()
        break
      case GUARD_IS_NOT_ADMIN:
        !this.props.admin ? next() : next(HOME_PAGE, { replace: true })
        break
      case GUARD_IS_NOT_LOGGED_IN:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (this.props.login) next(PROFILE_PAGE, { replace: true })
        else next()
        break
      default:
        next()
        break
    }
  }

  private isNotAdminGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_ADMIN)
  }

  private isAdminGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_ADMIN)
  }

  private isVerifiedGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_VERIFIED)
  }

  private isNotVerifiedGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_VERIFIED)
  }

  private isLoggedInGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_LOGGED_IN)
  }

  private isNotLoggedInGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_LOGGED_IN)
  }
  /**
   *
   * TODO
   * replace <GuardedRoute path="*" element={<Home />} />
   * by  <GuardedRoute path="*" element={<NotFound />} />
   * after migration from pharma to kiosk
   */
  public render = (): ReactNode => {
    if(this.props.maintenance || /^\/maintenance/.test(window.location.pathname)) {
      return <Maintenance />
    }
    if (window.location.pathname === NO_NETWORK_PAGE) return <NoNetwork />

    if (this.props.loading) return <Loading />

    if (!this.props.success) {
      return <NotAuthorized />
    }

    return (
      <GuardConfigProvider>
        <GuardProvider>
          <GuardedRoutes>
            <GuardedRoute
              path={MAINTENANCE_PAGE}
              element={<Maintenance />}
              />
            <GuardedRoute
              path="/"
              element={<Home />}
              guards={[this.isNotAdminGuard]}
            />
            <GuardedRoute
              path={HOME_PAGE}
              element={<Home />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute path={NO_NETWORK_PAGE} element={<NoNetwork />} />
            <GuardedRoute
              path={NOT_AUTHORIZED_PAGE}
              element={<NotAuthorized />}
            />
            <GuardedRoute
              path={SURVEY_PAGE}
              element={<Survey />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={VITALS_PAGE}
              element={<Vitals />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={FINALE_PAGE}
              element={<Finale />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={ADMIN_PAGE}
              element={<Admin />}
              guards={[this.isNotAdminGuard]}
            />
            <GuardedRoute
              path={UPLOAD_DOC_PAGE}
              element={<Upload />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={CONSENT_PAGE}
              element={<Consent />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={REGISTER_PAGE}
              element={<Signup />}
              guards={[this.isNotLoggedInGuard]}
            />
            <GuardedRoute
              path={PROFILE_PAGE}
              element={<Profile />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={LOGIN_PAGE}
              element={<Login />}
              guards={[this.isNotLoggedInGuard]}
            />
            <GuardedRoute
              path={CALL_PAGE}
              element={<Call />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={LOBBY_PAGE}
              element={<Lobby />}
              guards={[this.isNotLoggedInGuard]}
            />
            <GuardedRoute
              path={INSERT_RELATIVE_PAGE}
              element={<InsertRelative />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={SET_RELATIVE_PAGE}
              element={<SetRelativeGender />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={PAYMENT_PAGE}
              element={<Payment />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={VERIFY_PHONE_PAGE}
              element={<VerifyPhone />}
              guards={[this.isNotVerifiedGuard]}
            />
            <GuardedRoute
              path={ADMIN_CARERS_PAGE}
              element={<AdminCarer />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute
              path={STATISTICS_PAGE}
              element={<Statistics />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute
              path={NIR_AND_EXEMPTION_PAGE}
              element={<NirAndExemption />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute
              path={GET_DOCTOR_PAGE}
              element={<Doctors />}
              guards={[this.isLoggedInGuard]}
            />
            <GuardedRoute
              path={DOCTOR_CHOICE_PAGE}
              element={<DoctorChoice />}
              guards={[this.isLoggedInGuard]}
            />
            <GuardedRoute
              path={`${MODIFY_RELATIVE_PAGE}/:id`}
              guards={[this.isLoggedInGuard]}
              element={<ModifyRelative />}
            />
            <GuardedRoute
              path={RESET_PASSWORD_PAGE}
              guards={[this.isNotLoggedInGuard]}
              element={
                <ResetPassword
                  history={{
                    push: function (s: string): void {
                      throw new Error("Function not implemented.")
                    },
                  }}
                />
              }
            />
            <GuardedRoute
              path={TUTORIALS_PAGE}
              guards={[this.isAdminGuard]}
              element={
                <Tutorial
                  onChoice={function (state: TutoState): void {
                    throw new Error("Function not implemented.")
                  }}
                />
              }
            />
            <GuardedRoute path="*" element={<NotFound />} />
          </GuardedRoutes>
        </GuardProvider>
      </GuardConfigProvider>
    )
  }
}

export const mapStateToProps = ({
  client,
  configuration
}: {
  configuration: ConfigurationStore,
  client: ClientStore
}): RootProps => {
  return {
    success: client.success,
    loading: client.loading,
    admin: !!client.admin,
    login: !!client.customer,
    call: !!client.call && client.call.state !== CallState.ENDED,
    verified: client.customer?.is_verified ? true : false,
    maintenance: configuration?.configuration?.maintenance?.active,
  } as RootProps
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<RootProps> => {
  return {
    fetchInstallation: () => dispatch(client.installation),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root as any)
