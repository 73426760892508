import { Input } from "components/Input"
import { Modal } from "./Modal"
import { Keyboard } from "hocs/withKeyboard"
import { useEffect, useState } from "react"
import { languages } from "locales/languages"
import { Carousel } from "components/Carousel/Index"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Modal.module.scss";
import { RESEARCH_DOCTOR_REQUEST } from "containers/Doctors/services/constants"
export const DoctorCard = ({ doctor, onClick }) => {
    return <div className={styles.DoctorCard} onClick={onClick}>
        Dr. {doctor.firstname} {doctor.lastname}
    </div>
}

export const SearchDoctorModal = (props: any) => {
    const [request, setRequest] = useState("")
    const [doctors, setDoctors] = useState([])
    const { researchDoctors } = useSelector(({ doctors }) => doctors)
    const dispatch = useDispatch()

    useEffect(() => {
        if (request.length >= 3) {
            dispatch({ type: RESEARCH_DOCTOR_REQUEST, payload: request })
        } else {
            setDoctors([])
        }
    }, [request])

    useEffect(() => {
        setDoctors(researchDoctors)
    }, [researchDoctors])

    return <Modal title={languages.searchADoctor} visible={props.open} closable={true} onClose={props.onClose}>
        <div >
            <Keyboard
                options={{
                    type: "text",
                    inputName: "request",
                }}
                value={request}
                onChange={(value) => {
                    setRequest(value)
                }}
            >
                <div>
                    <Input
                        placeholder={languages.requestDoctorPlaceholder}
                        style={{ marginBottom: "50px" }}
                        value={request}
                        name="request"
                    />
                    <div className={styles.ContainerResult}>
                        {!doctors || !doctors.length
                            ? <div className={styles.NoResult}>{languages.noResultBeMorePrecise}</div>
                            : <Carousel
                                itemsPerPage={3}
                                items={doctors.map((doctor, key) =>
                                    <DoctorCard key={key} doctor={doctor}
                                        onClick={() => { props.onSelect(doctor) }}
                                    />
                                )}
                                infinite={false}
                            />}
                    </div>
                </div>
            </Keyboard>
        </div>
    </Modal>
}