import { select, put, takeEvery} from "redux-saga/effects";
import { GWatcher } from "types/redux";
import { Vitals } from "types/entity";
import { CREATE_VITALS } from "containers/Profile/services/constants";
import { GET_VITALS_BY_TYPE } from "./constants";
import { request } from "lib/request";
import { getCurrentSelectedPatientId } from "containers/Survey/services/selector";
import surveyActions from 'containers/Survey/services/actions';
import actions from "./action";

function* createVitals({ payload }: { type: string, payload: any}) {
  const relative_id = yield select(getCurrentSelectedPatientId);
  try {
    const res = yield request('/vitals/customers/create', { method: 'POST', payload: {...payload, relative_id: relative_id === 0 ? null : relative_id } })
    yield put(actions.addVitals({ value: res?.vital.value, type: res?.vital?.type, id: res.vital.id}, res.vital?.relative_id ? res.vital?.relative_id : 0))
    yield put(surveyActions.setVitalsAnswers());
  } catch(error) {
    console.error(error, {
      route: 'vitals/customers/create',
    })
  }
}

function* getVitalsByType({ payload } : { type: string, payload: Vitals}) {
  try {
    const res = yield request(`/vitals/customers/${payload.type}/last${payload.relative_id ? '?relative_id='+payload.relative_id : ''}`, {
      method: 'GET'
    });
    if(res.status === 'ok' && res.vital) {
      const currentPatientId = res.vital?.relative_id ? res.vital?.relative_id : 0;
      yield put(actions.addVitals({ value: res.vital.value, type: res.vital.type, id: res.vital.id }, currentPatientId));
    }
  } catch(error) {
    console.error(error, {
      route: '/vitals/customer/last'
    })
  }
}

function* VitalsWatcher(): GWatcher {
  yield takeEvery(CREATE_VITALS, createVitals);
  yield takeEvery(GET_VITALS_BY_TYPE, getVitalsByType);
}

export default VitalsWatcher;