import { Space, SpaceProps } from "antd"
import React from "react"
import styles from "./Spacer.module.scss"

const Spacer: React.FC<SpaceProps> = ({ children, ...rest }) => {
  return (
    <Space {...rest} className={styles.Spacer}>
      {children}
    </Space>
  )
}


export const Liner = ({ children }) => <div className="flex-center" style={{ gap: "20px", alignItems: "center" }}>
  <span style={{ background: "#B3BEC7", height: "1px", flex: 1 }}></span>
  <b style={{ fontSize: "30px" }}>
    {children}
  </b>
  <span style={{ background: "#B3BEC7", height: "1px", flex: 1 }}></span>
</div>
export default Spacer
