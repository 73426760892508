import { useEffect, useRef, useState } from "react"
import config from "react-global-configuration"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import Message from "components/Message/Message"
import { GenericModal, Modal } from "components/Modal/Modal"
import { cdn } from "core/cdn"
import { Doctor } from "types/entity"
import { Call, CallState } from "types/payload"
import { CallStore } from "types/store"

import styles from "./Call.module.scss"
import VideoProvider, {
  useVideoContext,
} from "./contexts/videoContext"
import { useLanguage } from "locales"
import useNetworkStatus from "./hooks/useNetworkStatus"

type VideoProps = CallStore

const formatAddress = ({ address }: { address: string }, office: string, not_informed_singular: string) => {
  return `${office} : ${address || not_informed_singular}`;
}

const formationLabel = {
  1: 'interne'
}

const civilityLabel = {
  "0": "Mme",
  "1": "M."
}

const CallBar = (props: { call: Call; hangup: () => void; cancelCall: () => void; }) => {
  const { languages } = useLanguage()
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isOpen, setIsOpen] = useState(true);
  const [callCancelOnGoing, setCallCancelOnGoing] = useState(false)

  const handleCancelCall = () => {
    setShowCancelModal(false)
    setCallCancelOnGoing(true)
    setTimeout(() => {
      props.cancelCall()
      setCallCancelOnGoing(false)
    }, 500)
  }

  const handleHangup = () => {
    if (props.call.doctor) {
      setModalVisibility(true)
    } else {
      setShowCancelModal(true)
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 5000)
  }, []);
  const [modalVisible, setModalVisibility] = useState<boolean>(false)
  const getDoctorName = (doctor: Doctor) => {
    return `Dr. (${civilityLabel[`${doctor.sex}` || "1"]}) ${doctor.firstname} ${doctor.lastname}`
  }

  return (
    <div id={styles.CallBar}>
      {props.call.doctor ? <div className={styles.DoctorInformation}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          {getDoctorName(props.call.doctor as Doctor)}
          {
            isOpen ? <img style={{ transform: "rotate(180deg)" }} src={cdn("icons/chevron.svg")} alt="chevron_down" onClick={() => setIsOpen(false)} width="18px" /> : <img src={cdn("icons/chevron.svg")} alt="chevron_down" onClick={() => setIsOpen(true)} width="18px" />
          }
        </div>

        {languages.generalist}
        <br />
        {`RPPS: ${(props.call.doctor as Doctor).rpps}`}
        {
          isOpen && (
            <>
              <br />
              {`${languages.teleconsultationTraining} : ${formationLabel[props?.call?.doctor?.formation_type || 1]}`}
              <br />
              {`${languages.medicalcenter} : ${languages.medikSante}`}
              <br />
              {formatAddress(props?.call?.doctor?.clinic, languages.office, languages.not_informed_singular)}
            </>
          )
        }
      </div> :
        <div className={styles.doctorIsComing}>
          <h1 className={styles.waitingRoomTitle}>
            {`${!callCancelOnGoing ? languages.doctorIsComingInLessThanFiveMinutes : languages.cancelling}`}
          </h1>
        </div>
      }
      {!props.call.doctor && <p className={styles.youCanJoinCallRoom}>
        {languages.pleaseWait}
      </p>}
      <Buttons style={{ display: "flex", justifyContent: "center", cursor: "pointer", zIndex: 100 }}>
        <div
          className={styles.hangupButton}
          onClick={handleHangup}
        >
          <img src={cdn("/icons/picto_hangup.svg")} />
        </div>
      </Buttons>
      <Modal
        title={languages.confirmHangupCall}
        visible={modalVisible}
        onAccept={props.hangup}
        onCancel={() => setModalVisibility(false)}
      />
      {showCancelModal &&
        <GenericModal
          footer={
            <Buttons style={{ paddingTop: 0, marginTop: 0 }}>
              <Button onClick={() => setShowCancelModal(false)} type="default">
                {languages.ICanWait}
              </Button>
              <Button onClick={handleCancelCall}
                type="primary" danger>
                {languages.IWantToLeave}
              </Button>
            </Buttons>
          }
          visible={showCancelModal}
          bodyStyle={{ padding: 0 }}
          title={<div className={styles.doctorIsComing_title__block}>
            <div className={styles.doctorIsComing_title__icon}>
              <img src={cdn("images/retard_illustration.svg")} alt="watch on hand" />
            </div>
            <div className={styles.doctorIsComing_title__text}>
              <span>{languages.doctorWillBeHereSoon}</span>
              <span><b>{languages.woudlYouRatherCancel}</b></span>
            </div>
          </div>}
        />
      }
    </div>
  )
}

const Connector = (props: { join: () => void, state: CallState }) => {
  const { languages } = useLanguage()
  const connectButtonRef = useRef<HTMLElement>(null)
  const [connecting, setConnecting] = useState<boolean>(false)
  const [sec, setSec] = useState<number>(config.get("timeout.before_connect"))

  useEffect(() => {
    const timer = setTimeout(function () {
      if (!(sec - 1)) {
        if (connectButtonRef?.current && !connecting) {
          setConnecting(true)
          connectButtonRef.current.click()
        }
        clearTimeout(timer)
      } else setSec(sec - 1)
    }, 1000)
  })

  return (
    <Card
      style={{ maxWidth: "none" }}
      subtitle={props.state === CallState.WAITING_ROOM ? "Nous vous reconnectons à la salle d'attente." : languages.doctorAwaiting}
      imgUrl={cdn("/images/tlc_commence_illustration.svg")}
      imgSize="large"
    >
      <Buttons>
        <Button ref={connectButtonRef} onClick={props.join}>
          {languages.joinCallIn} {sec}
        </Button>
      </Buttons>
    </Card>
  )
}

const VideoCall: React.FC<React.PropsWithChildren<VideoProps>> = (
  { call, message, hangup, cancelCall }
): JSX.Element => {
  return (
    <VideoProvider>
      <VideoContent call={call} message={message} hangup={hangup} cancelCall={cancelCall} />
    </VideoProvider>
  );
}

const VideoContent: React.FC<React.PropsWithChildren<Partial<VideoProps>>> = ({ call, message, cancelCall, hangup }) => {
  const { disconnect, connect, connected } = useVideoContext()
  const [patientInRoom, setPatientInRoom] = useState(false)
  const isOnline = useNetworkStatus();

  const handleHangup = () => {
    hangup();
    disconnect();
    setPatientInRoom(false)
  };

  const handleCancel = () => {
    cancelCall()
    disconnect();
    setPatientInRoom(false)
  }

  useEffect(() => {
    if (call.state === CallState.WAITING_ROOM && !connected && !patientInRoom && isOnline) {
      handleJoin()
      setPatientInRoom(true)
    }
  }, [call, connected, patientInRoom])

  useEffect(() => {
    if (!isOnline && connected) {
      setPatientInRoom(false)
      disconnect()
    }
  }, [isOnline])

  const handleJoin = () => {
    connect({
      token: call.customer_access_token,
      tool_token: call.tools_access_token,
      roomName: call.room_name,
    });
  };

  return (
    <div>
      <Message message={message} />
      {isOnline ?
        ((patientInRoom || connected) ? <CallBar call={call} hangup={handleHangup} cancelCall={handleCancel} /> : <Connector state={call.state} join={handleJoin} />)
        : <div className="flex-center">En attente de réseau...</div>}
    </div>
  );
};

export default VideoCall
