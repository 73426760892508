import React, { useState, useRef, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Information, Title } from 'components/Title/Title';
import { SliderNumber } from 'components/SliderNumber/SliderNumber';
import { cdn } from 'core/cdn';
import { languages } from 'locales/languages';
import styles from "./Tension.module.scss"
import { Buttons } from 'components/Card/Card';
import { Button } from 'components/Button';

const rangeValue = [{
  id: "sys",
  from: 41,
  to: 299,
  default: 120,
},
{
  id: "dia",
  from: 31,
  to: 199,
  default: 80
},
{
  id: "bpm",
  from: 30,
  to: 220,
  default: 60
}
]

const Tension = ({ currentValue, setCurrentValue, onSkip }) => {
  const videoRef = useRef(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [changedValue, setChangedValue] = useState({ dia: false, bpm: false, sys: false });
  const [localValue, setLocalValue] = useState({ sys: 120, dia: 80, bpm: 60 });
  const [step, setStep] = useState<'tutorial' | 'input'>('tutorial');
  const change = (type: "sys" | "bpm" | "dia", value) => {
    setLocalValue({ ...localValue, ...{ [type]: value } })
    setCurrentValue({ ...localValue, ...{ [type]: value } });
    if (rangeValue.find((r) => r.id === type)?.default !== value) {
      setChangedValue({ ...changedValue, ...{ [type]: true } });
    }
  }

  useEffect(() => {
    if (changedValue.sys && changedValue.bpm && changedValue.dia) {
      setDisabled(false);
    }
  }, [changedValue])


  if (step === 'tutorial') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Title text={languages.takeYourTension} />
        <video style={{ borderRadius: "15px", width: '-webkit-fill-available', maxHeight: "500px", marginTop: '20px', marginBottom: '40px' }} autoPlay id="remote-media"
          muted
          ref={videoRef}
          onEnded={() => {
            videoRef?.current?.play();
          }}>
          <source src={cdn("/videos/tutos/tensiometre.mp4")} type="video/mp4" />
          {languages.unableToProcessVideo}
        </video>
        <Buttons>
          <Button wide='long' htmlType='button' onClick={onSkip}>{languages.skipStep}</Button>
          <Button wide="long" htmlType='button' type='primary' onClick={() => setStep('input')}>{languages.iTookMyTension}</Button>
        </Buttons>
      </div>
    )
  }
  if (step === 'input') {
    return (
      <div>
        <Title text={languages.addYourTension} />
        <Row>
          <Col span={1} style={{ paddingTop: '20px' }}>
            <div style={{ justifyContent: "space-around", display: "flex", flexDirection: "column", height: "100%" }}>
              <div>
                {changedValue.sys &&
                  <img src={cdn('/images/vitals/tension_success.svg')} alt="success_blue_icon" />
                }
              </div>
              <div>
                {changedValue.dia &&
                  <img src={cdn('/images/vitals/tension_success.svg')} alt="success_blue_icon" />
                }
              </div>
              <div>
                {changedValue.bpm &&
                  <img src={cdn('/images/vitals/tension_success.svg')} alt="success_blue_icon" />
                }
              </div>
            </div>
          </Col>
          <Col span={3} style={{ paddingTop: '40px' }}>
            <div style={{ justifyContent: "space-around", display: "flex", flexDirection: "column", height: "100%" }}>
              <div>
                <Information size="large" text="SYS" style={{ marginBottom: 0, marginRight: 0 }} />
                <Information text="mmHg" />
              </div>
              <div>
                <Information size="large" text="DIA" style={{ marginBottom: 0 }} />
                <Information text="mmHg" />
              </div>
              <div>
                <Information size="large" text="PULSE" style={{ marginBottom: 0 }} />
                <Information text="/min" />
              </div>
            </div>
          </Col>

          <Col span={18}>
            <div className={styles.Container}>
              {rangeValue.map((range) =>
                <SliderNumber
                  minValue={range.from}
                  maxValue={range.to}
                  defaultValue={range.default}
                  onChange={(val) => {
                    change(range.id as "sys" | "bpm" | "dia", val)
                  }} />
              )}
            </div>
          </Col>
        </Row>
        <Buttons>
          <Button wide='long' type="link" htmlType='button' onClick={onSkip}>{languages.skipStep}</Button>
          <Button wide='long' type="primary" htmlType='submit' disabled={disabled}>{languages.confirm}</Button>
        </Buttons>
      </div>
    )
  }
}

export default Tension