import { Configuration } from "types/props"
import { Action, Message } from "types/redux"
import { ConfigurationStore } from "types/store"
import {
  CONFIGURATION_ERROR,
  CONFIGURATION_REQUEST,
  CONFIGURATION_SUCCESS,
  ACTIVATE_MAINTENANCE,
  DEACTIVATE_MAINTENANCE
} from "./constant"

const initialState: ConfigurationStore = {
  configuration: undefined,
  success: false,
  loading: false,
}
const reducer = function configurationReducer(
  state = initialState,
  action: Action<Configuration | Message>
): ConfigurationStore {
  switch (action.type) {
    case CONFIGURATION_REQUEST:
      return { ...state, success: false, loading: true }
    case CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: action.payload as Configuration,
        success: true,
        loading: false,
      }
    case CONFIGURATION_ERROR:
      return {
        ...state,
        message: action.payload as Message,
        success: false,
        loading: false,
      }
    case ACTIVATE_MAINTENANCE: 
      return {
        ...state, 
        configuration: {
          ...state.configuration,
          maintenance: {
            active: true,
            checkAPI: true,
          }
        }
      }
    case DEACTIVATE_MAINTENANCE: 
    return {
      ...state,
      configuration: {
        ...state.configuration,
        maintenance: {
          active: false,
          checkAPI: false,
        }
      }
    }
    default:
      return state
  }
}

export default reducer
