import { ADMIN_PAGE, CALL_PAGE, HOME_PAGE, LOBBY_PAGE, LOGIN_PAGE, REGISTER_PAGE, UPLOAD_DOC_PAGE } from "./core/constants"

const config = {
  /*
   *  Routes
   */
  installation : "/installations",
  terminals: {
    get: "/pharmacies/terminals/by-jwt",
    post: "/pharmacies/terminals/by-jwt",
    patch: "/pharmacies/terminals",
  },
  call : {
    hangup : "/calls/current/hangup",
    get : {
      current : "/calls/current",
      price:"/calls/price"
    },
    post: "/calls/create",
    put :  {
      current : "/calls/current",
    },
  },
  admin : {
    config : {
      method: "GET",
      route : "/pharmacies/config"
    },
    sendDocuments: "/pharmacies/documents/send",
    login:"/pharmacies/login",
    logout:"/pharmacies/logout",
    statistics:"/pharmacies/statistics",
    doctors: "/liberal/doctors",
    carers : "/pharmacies/carers",
    rpps: "/annuaire/doctors/search"
  },
  nirReader: {
    get : "/pharmacies/nir",
  },
  patient: {
    login : '/customers/login',
    login2fa: '/customers/2fa/login',
    login2faVerify: '/customers/2fa/login/verify',
    login2faResend: '/customers/2fa/login/token/',
    login2faToggle: '/customers/2fa/toggle',
    login2faToggleVerify: '/customers/2fa/toggle/verify',
    login2faToggleResend: '/customers/2fa/toggle/token/',
    login2faCancel: '/customers/2fa/login/cancel',
    logout : '/customers/logout',
    signup : '/customers/signup',
    verify : '/customers/verify',
    verifySendAgain:'/customers/signup/next',
    cancelSignup: "/customers/signup/cancel",
    put: {
      carers : `/customers/carers`,
      carers_relationships :`/customers/carers/relationships`,
      patient : "/customers/secured",
      relative : "/relatives",
    },
    post : {
      carers : `/customers/carers`,
      relative: `/relatives/create`,
      verify : '/customers/secured/verify',
      secured_token: `/customers/secured/token`,
      secured_update_cancel: "/customers/secured/cancel",
    },
    setupintent :{
      attach: "/cards/setupintent/attach",
      create: "/cards/setupintent/create",
    },
    get: {
      carers : `/customers/carers`,
      carers_relationship :`/customers/carers/relationships`,
      cards:'/cards/methods/current',
      payment_api_key: '/cards/payment_api_key',
      history : `/history/calls/customer`,
      relatives :  `/relatives`,
      document: {
        prescription: "/calls/prescription",
        workleave: "/calls/workleave",
        certificate: "/calls/certificate",
        report: "/calls/report",
      },
    },
    delete: {
      carers : `/customers/carers`,
    },
    sendLinkResetPassword: "/customers/recover_password",
    uploadLink: "/customers/photomaton/link",
    linkBySms : "/customers/photomaton/token/send",
    uploadedDocuments : "/documents/customer/all"
  },
  locations: "/locations/public/search",
  validator: {
    age : {
      minimum: 3,
      maximum: 110,
    }
  },
  mock: {
    noemail: "noemail@medadom.com",
    installation : {
      installation_identifier: "",
      device_model: "",
      app_version: "3.0.0",
      device_type: "Web",
      os_version: "",
      badge: "0",
      locale_identifier: "en",
      time_zone: "Europe/Paris",
      is_active: true,
      is_release: true,
      bundle: "",
    },
    doctors: [],
    call: {
      doctor:false, // Le patient a un médecin traitant ?
      doctor_available:false, // le médecin traitant du patient est disponible ?
      doctor_report:false, // Le patient autorise le médecin à partager son rapport ?
      exemption:false, // Le patient est exempt de paiement ? (AME,... ?)
      pharmacy: true, // La TLC est créée par une pharmacie ?
    },
  },
  timeout : {
    call_interval : 10000,
    before_connect : 3,
    nb_socket_try: 2,
    upload_doc_timer: 10,
    select_doc_time : 2,
    TwoFa_CodeResend_timer: 20,
  },
  checkInternetLobbyInterval : 5000, // check connection when waiting for doctor (in ms)
  intervalNirReaderApiInS: 2,
  timeoutNirReaderInS: 60,
  intervalUpdatorInMS: 300000, // 5min
  timeoutInactivity: {
    doNotTriggerOn: ["/", HOME_PAGE, ADMIN_PAGE, LOGIN_PAGE, LOBBY_PAGE, CALL_PAGE, UPLOAD_DOC_PAGE],
    minutes:  process.env.NODE_ENV !== "production" ? 10 : 10,
  },
  disconnectTimer: {
    minutes: 1,
    seconds: 30,
  },

  authorizedFeatures : {
    choiceOfDoctor : ["arthur@medadom.com", "antoine.delavelle@medadom.com"]
  },
  support : "01 86 65 55 15",
  newsletterSubPatientId: 12981482
}

export default config
