import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigurationStore } from 'types/store';
import actions from 'client/config/actions';
import { HOME_PAGE } from 'core/constants';
import { cdn } from 'core/cdn';
import styles from './maintenance.module.scss';

function Maintenance() {
  const dispatch = useDispatch();
  const maintenance = useSelector(({ configuration }: { configuration: ConfigurationStore }) => configuration?.configuration?.maintenance);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams.get('from') === 'api' && !maintenance?.active) {
      dispatch(actions.activateMaintenance());
    }
    const maintenanceInterval = setInterval(() => {
      fetch('/api/ping/isAlive', {method: 'POST', headers: {'Content-Type': 'application/json' },})
        .then((res) => {
          console.log('ping/isAlive/res', res);
          if(res.status !== 503) {
            // reactivate site
            dispatch(actions.deactivateMaintenance());
            window.location.href = HOME_PAGE;
          }
        })
        .catch((error) => console.error('error', error));
    }, 60 * 1000)
    return () => clearInterval(maintenanceInterval);
  }, [])
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <img src={cdn('/images/maintenance.svg')} alt="maintenance" />
      <br />
      <h1 className={styles.H1}>Maintenance en cours...</h1>
      <br />
      <h2 className={styles.H2}>Veuillez réessayer ultérieurement</h2>
    </div>
  )
}


export default Maintenance;