import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"

import { languages } from "locales/languages"
import { request } from "lib/request"
import { ResponseGetDoctors } from "types/payload"
import { GFlow, GWatcher } from "types/redux"

import actions from "./actions"
import { GET_AVAILABLE_DOCTORS_ERROR, RESEARCH_DOCTOR_SUCCESS, GET_AVAILABLE_DOCTORS_REQUEST, GET_AVAILABLE_DOCTORS_SUCCESS, GET_DOCTORS_REQUEST, RESEARCH_DOCTOR_REQUEST } from "./constants"

/*
 *  API REQUESTS
 */
async function getDoctorsApi(search?: string): Promise<ResponseGetDoctors> {
  return await request(
    `${config.get("admin.doctors")}?&request=${search}&page=0&limit=0`,
    {
      method: "GET",
    }
  )
}

/*
 * FLOWS
 */
function* getDoctorsFlow(): GFlow<ResponseGetDoctors> {
  try {
    const response: ResponseGetDoctors = yield call(getDoctorsApi)
    if (response.doctors) {
      yield all([put(actions.success(response.doctors))])
    }
  } catch (error) {
    console.error(error, {
      route: `${config.get("admin.doctors")}?&request=&page=0&limit=0`
    })
    yield put(
      actions.error(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}
function* researchDoctorsFlow(action: any) {
  try {
    const response: ResponseGetDoctors = yield getDoctorsApi(action.payload)
    if (response.doctors) {
      yield put({ type: RESEARCH_DOCTOR_SUCCESS, payload: response.doctors.filter((doctor) => doctor.is_available) })
    }
  } catch (error) {
    console.error(error, {
      route: `${config.get("admin.doctors")}?&request=&page=0&limit=0`
    })
    yield put(
      actions.error(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}

function* getAvailableDoctors() {
  try {
    const response = yield request("/available/doctors", { method: "GET" })
    yield put({
      type: GET_AVAILABLE_DOCTORS_SUCCESS, payload: {
        doctors: response.doctors
      }
    })
  } catch (e) {
    yield put({ type: GET_AVAILABLE_DOCTORS_ERROR })
  }
}

function* doctorWatcher(): GWatcher {
  yield takeLatest(GET_DOCTORS_REQUEST, getDoctorsFlow)
  yield takeLatest(RESEARCH_DOCTOR_REQUEST, researchDoctorsFlow)
  yield takeLatest(GET_AVAILABLE_DOCTORS_REQUEST, getAvailableDoctors)
}

export default doctorWatcher
