import { Value } from "containers/Survey/types";

import { Admin, Customer, Doctor, Gender } from "./entity";
import { Configuration } from "./props";
import { comparator, Unit } from "./store";

export type CustomerApi = Omit<Customer, APIProspectExclude> & APIProspectInclude
export type RelativeApi = Omit<Customer, APIProspectExclude> & APIProspectInclude
export type APIProspectExclude = "gender" | "birthdate" | "old-password"
export interface APIProspectInclude {
  sex: Gender
  birthdate: string // expected output : API_DATE_FORMAT = YYYY-MM-DD
  password_expiration_date: string // expected output : API_DATE_FORMAT = YYYY-MM-DD
  old_password?: string
}
/* ************ */
export interface ResponsePayload {
  status: string
}
export interface ResponseAPI<T> extends ResponsePayload {
  data: T
}
export interface ResponseGetDoctors extends ResponsePayload {
  doctors: Doctor[]
}
export interface ResponseRelatives extends ResponsePayload {
  relatives: RelativeApi[]
}

export interface ResponsePutRelative extends ResponsePayload {
  relative: RelativeApi
}

export interface ResponseConfiguration extends ResponsePayload {
  configuration: Configuration
}
export interface ResponsePutCustomer extends ResponsePayload {
  customer: CustomerApi
}

export interface ResponsePutCustomerVerify extends ResponsePayload {
  customer: CustomerVerify
}

export interface CustomerVerify {
  type: "email" | "phone" | "voice"
  update_verify_id: string
  recipient: string
}
export interface ResponseLogin extends ResponsePayload {
  customer?: CustomerApi
}
export interface twoFaData {
  phone: string
  mfa_verify_id?: string
  has_valid_email?: boolean
  transport?: string
  toggle?: boolean
}
export interface Response2faId extends ResponsePayload {
  customer?: twoFaData
}
export interface Verify2Fa {
  token: string
  mfa_verify_id: string
  toggle: boolean
}

export interface ResponseInsertRelative extends ResponsePayload {
  relative: RelativeApi
}
export interface ResponseInstallation extends ResponsePayload {
  // Pharmacy looks like an admin object
  pharmacy?: Admin
  customer?: CustomerApi
  doctor?: Doctor
  jwt: string
}

export interface ResponseVerifyPhone extends ResponsePayload {
  customer?: CustomerApi
}
export interface ResponseStatistics extends ResponsePayload {
  statistics: Statistics
}
export interface ResponseAdmin extends ResponsePayload {
  pharmacy?: Admin
  customer?: CustomerApi
}
export interface WebSocketMessage {
  type: string,
  action: string,
  result: string,
  body: unknown
}

export interface ResponseCall extends ResponsePayload {
  call?: Call
  requires_action?: boolean
  payment_intent_client_secret?: string
}
export interface ResponseHistory extends ResponsePayload {
  calls?: Call[]
}
export interface ResponsePrice extends ResponsePayload {
  content: string
  price: string
}
export type ResponseSignup = ResponsePayload
export interface InstallationRequest {
  installation_identifier: string
  app_version: string
  device_type: string
  device_model: string
  os_version: string
  badge: string
  locale_identifier: string
  time_zone: string
  is_active: boolean
  is_release: boolean
  bundle: string
}
export type InsertRelativeRequest = RelativeApi

export interface CarerApi extends RelativeApi {
  email: string
  phone: string
  guid: string
}
export interface CallRequest {
  medical?: {
    illness?: Value
    illness_details?: Value
    allergies?: Value
    allergies_details?: Value
    operations?: Value
    pregnant?: Value
    drugs?: Value
    reason?: Value
  },
  doctor: string | boolean
  doctor_id: string | null
  clinic_id: string | null
  doctor_rpps: string | null
  doctor_name: string | null
  doctor_available: string | boolean
  doctor_report: string | boolean
  relative_id: string | null
  payment_method_id?: string | null
  pharmacy: boolean
  exemption: boolean
}

export interface Eta {
  computed?: number
  displayed: string
}
export interface Call {
  category_id: number
  created_at: Date
  doctor?: Doctor
  customer: Customer
  customer_access_token: string
  tools_access_token?: string
  end_date: Date
  expires_at: string
  id: number
  is_certificate: boolean
  is_fds: boolean
  is_prescription: boolean
  is_report: boolean
  is_workleave: boolean
  pharmacy?: Admin
  room_name: string
  start_date: Date
  state: CallState
  updated_at: Date
  chosen_doctor_id?: string
  eta?: Eta
}

export enum CallState {
  CANCELLED_BY_DOCTOR = -1,
  CANCELLED_BY_PATIENT = -2,
  INITIALIZED = 0,
  WAITING_ROOM = 6,
  FIFO_WAITING = 0.1,
  ACCEPTED = 1,
  ACCEPTED_AND_SOON = 1.1,
  STARTED = 2,
  HUNGUP = 3,
  ENDED = 4,
  EXPIRED = 5,
}
export interface Credential {
  password: string
}
export interface twoFaCode {
  code: string
}
export interface PhoneCredential extends Credential {
  phone: string
}
export interface EmailCredential extends Credential {
  email: string
}
export interface SocketSendMessage {
  type: "ophtalmology" | "carte_vitale" | "infos"
  action:
  | "get" | "all_infos"

  result?: "OK" | "KO"
  body: {
    [x: string]: unknown
  }
}

export interface Statistics {
  tlcs: {
    daily: number,
    weekly: number,
    monthly: number,
    yearly: number,
    total: number
  }
}

export interface TerminalInformation {
  terminal?: {
    hostname?: string,
    api_lec_version?: string,
    websocket_version?: string,
    webbrowser_version?: string,
    os_name?: string,
    os_version?: string,
    type?: string,
    ipv6?: string,
    ipv4_public?: string,
    ipv4_private?: string,
  },
  manageengine?: {
    host_id?: string,
    version?: string,
  },
  teamviewer?: {
    host_id?: string,
    alias?: string,
    version?: string,
    type?: string,
  }
}

export type vitalsType = 'weight' | 'temperature' | 'spo2' | 'bp' | 'heartrate'

export interface VitalsPayload {
  type: vitalsType
  id: string,
  value: {
    comparator: comparator,
    raw: string,
    unit: Unit
  }
}