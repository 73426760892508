import { useState } from "react"
import { cdn } from "core/cdn"
import styles from "./Call.module.scss"
import { Button } from "components/Button"
import { Important, Title } from "components/Title/Title"
import { languages } from "locales/languages"
import { useDispatch, useSelector } from "react-redux"
import { joinVirtualRoom } from "./services/actions"
import { Modal } from "components/Modal/Modal"
import { Buttons } from "components/Card/Card"
import { REMOVE_CHOSEN_DOCTOR_REQUEST } from "./services/constants"

export const LessThanFiveMinutesPages = () => {
  const [modalRemoveChosenDoctorVisible, setModalRemoveChosenDoctorVisible] = useState(false)
  const dispatch = useDispatch()
  const { successDeleteDoctor, loadingDeleteDoctor, call, loading } = useSelector(({ call }) => call)

  return (
    <div className={styles.LessThanFiveMinutesBloc}>
      <h1 className={styles.waitingRoomTitle}>
        {languages.tlcExpectedIn}
      </h1>
      <div style={{ display: "flex" }}>
        <img src={cdn("/loaders/gimmick-video.gif")} alt="" height="90" />
        <Important shape={true} text={languages.lessThanFiveMinutes} />
      </div>
      <img src={cdn("/images/tlc_terminee_illustration.svg")} alt="doctor image" style={{ width: "250px" }} />

      {(call.chosen_doctor_id && !successDeleteDoctor) ? (
        <Button 
          type="primary" 
          disabled={loadingDeleteDoctor} 
          loading={loadingDeleteDoctor} 
          onClick={() => setModalRemoveChosenDoctorVisible(true)}
        >
          Réduire l'attente
        </Button>
      ) : (
        <div className="flex-center flex-column" style={{ gap: "30px" }}>
          <p className={styles.youCanJoinCallRoom}>
            {languages.youCanJoinCallsRomm}
          </p>
          <Button
            wide="long"
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={() => {
              dispatch(joinVirtualRoom())
            }}
          >
            {languages.joinVirtualRoom}
          </Button>
        </div>
      )}

      <Modal 
        closable 
        visible={modalRemoveChosenDoctorVisible} 
        noFooter 
        onClose={() => setModalRemoveChosenDoctorVisible(false)}
      >
        <div className="flex-center flex-column" style={{ gap: "50px" }}>
          <img
            src={cdn("images/warning_picto.svg")}
            alt="warning icon"
          />
          <Title 
            size="small" 
            text={languages.toReduceWaitingTime} 
          />
          <Buttons direction="vertical">
            <Button 
              wide="long" 
              type="primary" 
              onClick={() => { 
                setModalRemoveChosenDoctorVisible(false)
                dispatch({ type: REMOVE_CHOSEN_DOCTOR_REQUEST }) 
              }}
            >
              Élargir ma demande
            </Button>
          </Buttons>
        </div>
      </Modal>
    </div>
  )
}