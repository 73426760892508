
import { languages } from "locales/languages"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "components/Modal/Modal"
import { AvailableDoctor, Doctor, GenderDoctor } from "types/entity"
import { useNavigate } from "react-router-dom"
import surveyActions from "containers/Survey/services/actions"
import { SURVEY_PAGE } from "core/constants"
import PreviousButton from "components/Previous/PreviousButton"
import { Title } from "components/Title/Title"
import { Buttons, Card } from "components/Card/Card"
import { Liner } from "components/Spacer"
import { Button } from "components/Button"
import Loader from "components/Loaders/Loader/Loader"
import { GET_AVAILABLE_DOCTORS_REQUEST } from "../services/constants"

import styles from "./DoctorChoice.module.scss"
import { cdn } from "core/cdn"
import { Carousel } from "components/Carousel/Index"
import { SearchDoctorModal } from "components/Modal/SearchDoctorModal"

export const DoctorCard = ({ children, ...props }) => {
    return <div className={styles.DoctorCard} {...props}>
        {children}
    </div>
}

const TeleconsultNow = ({ onSelect, onClose, chosenDoctor }) => {
    return <>
        <Liner>
            {languages.or}
        </Liner>
        <Buttons size={50} direction="vertical">
            <Title size="small" text={languages.consultNowWithDoctor} />
            <Button type="primary" onClick={() => onSelect(null)}>{languages.teleconsultNow}</Button>
        </Buttons>
        <Modal closable visible={!!chosenDoctor} noFooter onClose={onClose}>
            <div className="flex-center flex-column" style={{ gap: "50px" }} >
                <img
                    src={cdn("images/warning_picto.svg")}
                    alt="warning icon"
                />
                <Title size="small" text={languages.doctorGuaranteeWarning} />
                <Buttons direction="vertical">
                    <Button wide="long" type="primary" onClick={() => onSelect(null)} >
                        {languages.expandMyRequest}
                    </Button>
                    <Button size="small" type="link" onClick={() => onSelect(chosenDoctor)}>
                        {languages.teleconsultWithChosenDoctor}
                    </Button>
                </Buttons>
            </div>
        </Modal>
    </>
}

export const doctorLabel = (doctor: any) => {
    return <>
        <b style={{ fontSize: "22px" }}>
            Dr. ({doctor.sex === GenderDoctor.FEMALE ? "Mme" : "M."}) {" "}
            {doctor.firstname} {doctor.lastname.toUpperCase()}
        </b>
        <br />
        <span style={{ color: "#5E7587" }}>
            ({doctor.onsite_zipcode}) {doctor.clinic.city}
        </span>
    </>
}

export const HasDoctorsNearby = (props: any) => {
    const [chosenDoctor, setChosenDoctor] = useState(null)
    return <Card title={languages.doctorsNearby} >
        <PreviousButton
            onClick={props.onCancel}
        />

        {!props.doctors?.length && <span style={{ color: "#5E7587", alignSelf: "center" }}>{languages.noDoctorOnline}</span>}
        <div style={{ height: "340px" }}>
            <Carousel
                itemsPerPage={3}
                items={props.doctors.map((doctor, key) =>
                    <DoctorCard key={key}
                        onClick={() => { setChosenDoctor(doctor) }}
                    >{doctorLabel(doctor)}</DoctorCard>)}
                infinite={false}
            />
        </div>
        <TeleconsultNow chosenDoctor={chosenDoctor} onClose={() => setChosenDoctor(null)} onSelect={props.onSelect} />
    </Card>
}

export const DoctorsChoice = (props: any) => {
    const [chosenDoctor, setChosenDoctor] = useState(null)
    const [openSearchDoctorModal, setOpenSearchDoctorModal] = useState(false)

    return <Card>
        <PreviousButton
            onClick={props.onCancel}
        />
        <Title size="small" text={languages.doctorsOnline} />
        <div className="flex-center" style={{ marginTop: "20px" }}>
            <div className={styles.SearchButton} onClick={() => setOpenSearchDoctorModal(true)}>
                <img src={cdn("/icons/search_picto.svg")} alt="" />
                {languages.searchADoctor}
            </div>
        </div>
        <div style={{ height: "340px" }}>
            {!props.doctors?.length ?
                <div className="flex-center" style={{ height: "100%" }}>
                    <span className={styles.NoResult}>{languages.noDoctorOnline}</span>
                </div> :
                <Carousel
                    itemsPerPage={3}
                    items={props.doctors.map((doctor, key) =>
                        <DoctorCard key={key}
                            onClick={() => { setChosenDoctor(doctor) }}
                        >{doctorLabel(doctor)}</DoctorCard>)}
                    infinite={false}
                />
            }
        </div>


        <TeleconsultNow chosenDoctor={chosenDoctor} onClose={() => setChosenDoctor(null)} onSelect={props.onSelect} />
        <SearchDoctorModal
            open={openSearchDoctorModal}
            onClose={() => setOpenSearchDoctorModal(false)}
            onSelect={(d) => {
                setOpenSearchDoctorModal(false);
                setChosenDoctor(d)
            }} />
    </Card>
}

export const NoDoctorNearby = (props: any) => {
    const [selectDoctor, setSelectDoctor] = useState(false)
    if (selectDoctor) return <DoctorsChoice doctors={props.doctors} onSelect={props.onSelect} onCancel={() => props.onCancel()} />
    return <Card title={languages.consultNowWithDoctorOrChoseHim} imgUrl={cdn("/images/tlc_terminee_illustration.svg")}>
        <PreviousButton
            onClick={props.onCancel}
        />
        <Buttons direction="vertical">
            <Button type="primary" onClick={() => props.onSelect(null)}>{languages.teleconsultNow}</Button>
            <div className="small-link" onClick={() => setSelectDoctor(true)}>
                {languages.choseDoctorWithoutGuarantee}
            </div>
        </Buttons>

    </Card>
}

export const DoctorChoice = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { doctors, loading } = useSelector(({ doctors }) => doctors)

    useEffect(() => {
        dispatch({ type: GET_AVAILABLE_DOCTORS_REQUEST })
        dispatch(surveyActions.unset())
    }, [])

    if (loading) return <Loader />
    else return <NoDoctorNearby doctors={doctors} onSelect={(doctor?: Doctor) => {
        dispatch(surveyActions.set([
            {
                title: "chosen-doctor",
                value: doctor as AvailableDoctor,
            },
        ]))
        dispatch(surveyActions.next())
        navigate(SURVEY_PAGE)
    }} onCancel={() => {
        dispatch(surveyActions.unset())
        dispatch(surveyActions.previous())
        navigate(SURVEY_PAGE)
    }} />

}