/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Buttons, Card } from "components/Card/Card";
import { useDispatch, useSelector } from "react-redux";
import { getVitalsNeededByReason } from "./utils";
import { Keyboard } from "hocs/withKeyboard";
import { Input } from "components/Input";
import { Button } from "components/Button";
import vitalsActions from "containers/Vitals/services/action";
import surveyActions from "containers/Survey/services/actions";
import { getReasonAnswer } from "containers/Survey/services/selector";
import { languages } from "locales/languages";
import { cdn } from "core/cdn";
import { useNavigate } from "react-router-dom";
import { SURVEY_PAGE } from "core/constants";
import { getSelectedPatientVitals } from "./services/selector";
import { Steps } from "components/Steps";
import { SurveyStore } from "types/store";
import PreviousButton from "components/Previous/PreviousButton";
import { vitals as vitalList } from "./services/constants";
import { Title } from "components/Title/Title";
import Temperature from "components/Input/Temperature/Temperature";
import { vitalsType } from "types/payload";
import Tension from './components/Tension';
// import { Modal } from 'antd'
import { GenericModal } from "components/Modal/Modal";

const SaturationForm = ({currentValue, setCurrentValue, onSkip}) => {
  const videoRef = useRef(null);
  const [step, setStep] = useState<'tutorial' | 'input'>('tutorial')
  if(step === 'tutorial') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
        <Title text={languages.takeYourSaturation} />
        <video style={{ borderRadius:"15px", width: '-webkit-fill-available', maxHeight: "500px", marginTop: '20px', marginBottom: '40px' }} autoPlay id="remote-media"
        muted
        ref={videoRef}
        onEnded={() => {
            videoRef?.current?.play();
        }}>
          <source src={cdn("/videos/tutos/oxymetre.mp4")} type="video/mp4"/>
          {languages.unableToProcessVideo}
        </video>
        <Buttons>
          <Button wide='long' htmlType='button' onClick={onSkip}>{languages.skipStep}</Button>
          <Button wide="long" htmlType='button' type='primary' onClick={() => setStep('input')}>J'ai pris ma saturation</Button>
          </Buttons>
      </div>
    )
  }
  if(step === 'input') {
    return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <Title text={languages.addYourSaturation} />
                <img src={cdn("images/saturation.svg")} alt="scale" width={120} />
              <Keyboard options={{ type: 'code', unit: '%SpO2', inputName: 'saturation' }} value="0" onChange={(value) => {
                  setCurrentValue(value)
                }} >
                  <Input type="text" value={currentValue} name="saturation" width={30} maxLength={3} />
              </Keyboard>
            <Button type="primary" wide="long" htmlType="submit" disabled={!currentValue || currentValue === 0}>{languages.confirm}</Button>
            <Button type="link" htmlType="button" wide="long" onClick={onSkip}>{languages.skipStep}</Button>
    </div>)
    }
};

const WeightForm = ({currentValue, setCurrentValue, onSkip}) => {
return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <img src={cdn("images/scale.svg")} alt="scale" width={120} />
          <Keyboard options={{ type: 'code', unit: 'kg', inputName: 'weight' }} value="0" onChange={(value) => { setCurrentValue(value) }} >
            <Input type="text" value={currentValue} name="weight" width={30} maxLength={3} />
          </Keyboard>
        <Button type="primary" wide="long" htmlType="submit" disabled={!currentValue || currentValue === "0"}>{languages.confirm}</Button>
        <Button type="link" htmlType="button" wide="long" onClick={onSkip}>{languages.skipStep}</Button>
</div>)};

const TemperatureForm = ({ currentValue, setCurrentValue, onSkip}) => {
  const videoRef = useRef(null);
  const [step, setStep] = useState<'tutorial' | 'input'>('tutorial');
  if(step === 'tutorial') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
        <Title text={languages.takeYourTemperature} />
        <video style={{ borderRadius:"15px", width: '-webkit-fill-available', maxHeight: "500px", marginTop: '20px', marginBottom: '40px' }} autoPlay id="remote-media"
        muted
        ref={videoRef}
        onEnded={() => {
            videoRef?.current?.play();
        }}>
          <source src={cdn("/videos/tutos/thermometre.mp4")} type="video/mp4"/>
          {languages.unableToProcessVideo}
        </video>
        <Buttons>
          <Button wide="long" htmlType="button" onClick={onSkip}>{languages.skipStep}</Button>
          <Button wide="long" htmlType="button" type="primary" onClick={() => setStep('input')}>{languages.iTookMyTemperature}</Button>
        </Buttons>
      </div>
    )
  }
  if(step === 'input') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
        <Title text={languages.addYourTemperature} />
        <img src={cdn("/images/thermometer.svg")} alt="thermometer" width={120} />
        <Keyboard options={{ type: 'twoFa', inputName: 'temperature'  }} value="0" onChange={(value) => {
            setCurrentValue(value)
          }} >
          <Temperature value={currentValue} name="temperature" />
        </Keyboard>
          <Button wide="long" type="primary" htmlType="submit" disabled={!currentValue || currentValue === 0 || currentValue.toString().length <3}>{languages.confirm?.toUpperCase()}</Button>
          <Button wide="long" htmlType="button" type="link" onClick={onSkip}>{languages.skipStep}</Button>
      </div>
  )}
}

const getTitle = (type) => {
  switch(type) {
    case vitalList.WEIGHT: 
      return languages.addYourWeight;
    default:
      return undefined;
  }
}

const getCurrentTypeUnit = (type) => {
    switch(type) {
      case vitalList.WEIGHT: 
        return 'Kg';
      case vitalList.SATURATION: 
        return '% SpO2';
      case vitalList.TENSION: 
        return 'mmHg';
      case vitalList.TEMPERATURE: 
        return '°'
      default:
        return undefined;
    }
}
export function Vitals() {
  const navigate = useNavigate();
  const { questions } = useSelector(({ survey }: { survey : SurveyStore }) => survey)
  const currentReason = useSelector(getReasonAnswer) as string;
  const vitals = useSelector(getSelectedPatientVitals);
  const vitalsNeeded = getVitalsNeededByReason(currentReason);
  const [wrongValueModal, setWrongValueModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentType, setCurrentType] = useState<vitalsType>(vitalsNeeded?.[currentStep]?.label);
  const [currentValue, setCurrentValue] = useState<any>(undefined);
  const dispatch = useDispatch();
  useEffect(() => {
    if([vitalList.WEIGHT, vitalList.TEMPERATURE, vitalList.SATURATION, vitalList.TENSION].includes(vitalsNeeded?.[currentStep]?.label || '')) {
      setCurrentValue('');
      setCurrentType(vitalsNeeded[currentStep]?.label as any);
    } else {
      dispatch(surveyActions.next());
      navigate(SURVEY_PAGE);
    }
  }, [currentStep])

  useEffect(() => {
    // weight is not alwaysAsked, if set, go to the next step
    if(vitals?.[vitalList.WEIGHT]?.value) {
     setCurrentStep(currentStep + 1);
    }
  }, [])
  return (
    <>
    <Steps array={questions.map((q) => q.id)} current={'reason'} />
      <PreviousButton onClick={() => {
        if(currentStep > 0) {
          if(vitalsNeeded[currentStep - 1]?.label === vitalList.WEIGHT && vitals?.[vitalList.WEIGHT]?.value) {
            dispatch(surveyActions.previous()); navigate(SURVEY_PAGE)
          } else {
            setCurrentStep(currentStep -1);
          }
        } else {
          dispatch(surveyActions.previous()); navigate(SURVEY_PAGE)};
        }
        }/>
      <Card title={getTitle(currentType)}>
        <form onSubmit={(e) => {
        e.preventDefault();
        let rawValue = currentValue;
        if(currentType === vitalList.TEMPERATURE && currentValue?.length === 3) {
          // validate value before sending it
          if(currentValue < 320 || currentValue > 450) {
            setWrongValueModal(true);
            return;
          }
          const splittedValue = currentValue.split('');
          rawValue = splittedValue[0] + splittedValue[1] + ',' + splittedValue[2];
        }
        if(currentType === vitalList.SATURATION) {
          if(currentValue > 100 || currentValue < 70) {
            setWrongValueModal(true);
            return;
          }
        }
        if(currentType === vitalList.TENSION) {
          dispatch(vitalsActions.createVitals({type: 'heartrate', value: { raw: rawValue.bpm, unit: 'bpm', comparator: "=" }, note: ''}));
          dispatch(vitalsActions.createVitals({type: 'bp', value: {systolic: { raw: rawValue.sys, unit: getCurrentTypeUnit(vitalList.TENSION), comparator: '='}, diastolic: {raw: rawValue.dia, unit: getCurrentTypeUnit(vitalList.TENSION), comparator: '='}, raw: rawValue.bpm, unit: 'bpm', comparator: '='}, note: ''}));
        } else if(rawValue) {
            dispatch(vitalsActions.createVitals({ type: currentType, value: { raw: rawValue, unit: getCurrentTypeUnit(currentType), comparator: "=" }, note: '' }))
        }
        setCurrentStep(currentStep+1);
      }}>
          {
            currentType === vitalList.WEIGHT && <WeightForm currentValue={currentValue} setCurrentValue={setCurrentValue} onSkip={() => setCurrentStep(currentStep+1)} />
          }
          {
            currentType === vitalList.TEMPERATURE && <TemperatureForm currentValue={currentValue} setCurrentValue={setCurrentValue} onSkip={() => {dispatch(vitalsActions.unsetVitalsByTypeSaga({type: vitalList.TEMPERATURE })); setCurrentStep(currentStep+1)}} />
          }
          {
            currentType === vitalList.SATURATION && <SaturationForm currentValue={currentValue} setCurrentValue={setCurrentValue} onSkip={() => {dispatch(vitalsActions.unsetVitalsByTypeSaga({type: vitalList.SATURATION})); setCurrentStep(currentStep+1)}} />
          }
          {
            currentType === vitalList.TENSION && <Tension currentValue={currentValue} setCurrentValue={setCurrentValue} onSkip={() => {
              dispatch(vitalsActions.unsetVitalsByTypeSaga({type: vitalList.TENSION}));
              dispatch(vitalsActions.unsetVitalsByTypeSaga({type: vitalList.HEARTRATE}));
              setCurrentStep(currentStep+1)
            }} />
          }
        </form>
        <GenericModal style={{width: '600px'}} visible={wrongValueModal} closable={false} footer={<></>} >
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={cdn('/images/warning_picto.svg')} alt="warning_picto" />
          <br />
          <h2 style={{color: '#211452'}}>{languages.correctYourVitalsValue(currentType)}</h2>
          <br />
          <Button type="primary" wide="long" onClick={() => setWrongValueModal(false)}>{languages.fix?.toUpperCase()}</Button>
          </div>
        </GenericModal>
      </Card>
    </>
  )
}