import React, { useEffect, useState } from "react"

import { Buttons } from "components/Card/Card"
import { GenericCancelModal, Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Important, Title } from "components/Title/Title"
import { cdn } from "core/cdn"
import { Call, Eta } from "types/payload"
import { useLanguage } from "locales"
import { Button } from "components/Button"
import { useDispatch, useSelector } from "react-redux"
import { REMOVE_CHOSEN_DOCTOR_REQUEST } from "./services/constants"
import { LessThanFiveMinutesPages } from "./LessthanFiveMin"
import { canCustomerJoinRoom } from "./utils"


export const FifoWaiting: React.FC<{
  call: Call
  eta: Eta
  loading: boolean
  cancelCall: () => void
}> = ({ eta, cancelCall, loading, call }): JSX.Element => {
  const { languages } = useLanguage()
  const [modalCancelTlcVisible, setModalCancelVisible] = useState(false)
  const [modalRemoveChosenDoctorVisible, setModalRemoveChosenDoctorVisible] = useState(false)
  const { successDeleteDoctor, loadingDeleteDoctor } = useSelector(({ call }) => call)
  const dispatch = useDispatch()
  const [canJoinRoom, setCanJoinRoom] = useState(false)

  useEffect(() => {
    if (canCustomerJoinRoom(eta.computed)) {
      setCanJoinRoom(true)
    }
  }, [setCanJoinRoom, eta])

  return (
    <>
      <PreviousButton
        text={languages.cancel}
        onClick={() => setModalCancelVisible(true)}
      />
      {canJoinRoom ?
        <LessThanFiveMinutesPages />
        :
        <Buttons direction="vertical" style={{ marginTop: "0" }}>
          {eta.computed !== null && (
            <b className="font-size-large">{languages.tlcExpectedIn}</b>
          )}
          <div className="center flex">
            <img src={cdn("/loaders/gimmick-video.gif")} alt="" height="90" />
            <Important shape={true} text={`${eta.displayed}`} />
          </div>
          <p className="font-size-large">
            {languages.youWillBeInformedBySMSFifo}{" "}
          </p>
          {
            (call.chosen_doctor_id && !successDeleteDoctor) &&
            <Button type="primary" disabled={loadingDeleteDoctor} loading={loadingDeleteDoctor} onClick={() => setModalRemoveChosenDoctorVisible(true)}> Réduire l'attente </Button>
          }
        </Buttons>}
      <GenericCancelModal
        loading={loading}
        title={languages.confirmCancelCall}
        visible={modalCancelTlcVisible}
        onCancel={cancelCall}
        acceptText={languages.cancelCall}
        acceptButtonProps={{ danger: true, type: "primary" }}
        onAccept={() => setModalCancelVisible(false)}
      />

      <Modal closable visible={modalRemoveChosenDoctorVisible} noFooter onClose={() => setModalRemoveChosenDoctorVisible(false)}>
        <div className="flex-center flex-column" style={{ gap: "50px" }} >
          <img
            src={cdn("images/warning_picto.svg")}
            alt="warning icon"
          />
          <Title size="small" text={languages.toReduceWaitingTime} />
          <Buttons direction="vertical">
            <Button wide="long" type="primary" onClick={() => { setModalRemoveChosenDoctorVisible(false); dispatch({ type: REMOVE_CHOSEN_DOCTOR_REQUEST }) }} >
              Élargir ma demande
            </Button>
          </Buttons>
        </div>
      </Modal>
    </>
  )
}
